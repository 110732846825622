import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../stores";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { Button, Col, Image, Input, Layout, Row, Typography } from "antd";
import Loading from "../../components/Loading";
import ErrorPage from "../Error";
import CustomSearchIcon from "../../components/Icons/CustomSearchIcon";
import CustomFilterIcon from "../../components/Icons/CustomFilterIcon";
import { debounce, imageFallback } from "../../utils";
import { useTranslation } from "react-i18next";
import NoData from "../../components/NoData";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
const { Content } = Layout;

const HIDDEN_BOOKS = ["32712fc2-3420-4886-8ddc-d95a3b06cc25", "d772d720-99b3-42e5-ad08-94fd34a6178d"];

function MyLibrary(props) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { me } = useContext(StoreContext);
    const [filteredBooks, setFilteredBooks] = useState();
    const currentBook = JSON.parse(localStorage.getItem("currentBook"));
    const [currentPage, setCurrentPage] = useState(1);
    const {
        isError,
        data: books = [],
        isLoading,
    } = useQuery(
        ["dashboard"],
        async () => {
            const res = await handleFetchApi("get", `admin/book-codes/look-up/access-code/users/${me?.id}?size=99&page=0`);

            return res?.data?.content?.filter((book) => !HIDDEN_BOOKS.includes(book.id));
        },
        {
            staleTime: 18000,
            cacheTime: 18000,
            refetchOnWindowFocus: false,
        }
    );

    const handleFilterBooks = useCallback(
        (keyword) => {
            if (!keyword) {
                setFilteredBooks();
                return;
            }
            const results = books?.filter((b) => {
                const tLowercase = b.title?.toLowerCase();
                return tLowercase.includes(keyword);
            });
            if (results?.length === books?.length) setFilteredBooks([]);
            else setFilteredBooks(results || []);
        },
        [books]
    );

    const BookList = React.memo(({ items }) => {
        if (!items || items.length === 0) {
            return <NoData text={t("noSearchResults")} />;
        }

        const itemsPerPage = window.innerWidth < 768 ? 4 : window.innerWidth < 1280 ? 6 : 8;
        const totalPages = Math.ceil(items.length / itemsPerPage);
        const paginatedItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

        return (
            <>
                <Row gutter={[24, 24]}>
                    {paginatedItems.map((book, index) => {
                        return (
                            <Col
                                onClick={() => {
                                    localStorage.setItem("currentBook", JSON.stringify(book));
                                    navigate(`/book/${book?.book_id}`);
                                }}
                                span={24 / (itemsPerPage / 2)}
                                key={book.book_id + index}
                                className="flex__start__start flex__column book_item"
                            >
                                <Image width={"100%"} preview={false} src={book?.cover_url} alt="book_image" fallback={imageFallback} />
                                <b>{book?.title?.toUpperCase()}</b>
                            </Col>
                        );
                    })}
                </Row>
                <div className="pagination flex__center__center gap">
                    <Button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
                        <LeftOutlined />
                    </Button>
                    <span>
                        {currentPage} / {totalPages}
                    </span>
                    <Button disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
                        <RightOutlined />
                    </Button>
                </div>
            </>
        );
    });

    if (isError) return <ErrorPage />;

    return (
        <Content>
            <div className="page my_library">
                <Row gutter={[32, 32]}>
                    <Col xs={24} md={24} lg={17}>
                        <div className="flex__between__center gap">
                            <Input
                                onChange={debounce((e) => handleFilterBooks(e.target.value?.toLowerCase()), 300)}
                                prefix={<CustomSearchIcon />}
                                placeholder={t("search_by_book_name")}
                                size="large"
                                className="--border-dark"
                            />
                            <Button icon={<CustomFilterIcon />} className="flex__start__center btn-white un-transition" size="large">
                                {t("add_filter")}
                            </Button>
                        </div>
                        <br />
                        {filteredBooks ? (
                            <div className="new_actived_book">
                                <Typography.Title level={4}>{t("search_results", { count: filteredBooks?.length })}</Typography.Title>
                                <BookList items={filteredBooks} />
                            </div>
                        ) : (
                            <div className="new_actived_book">
                                <Typography.Title level={4}>{t("newly_activated_books")}</Typography.Title>
                                <BookList items={books} />
                            </div>
                        )}
                    </Col>
                    <Col xs={24} md={24} lg={7} className="book_reading">
                        <Typography.Title level={4}>{t("book_reading")}</Typography.Title>
                        {currentBook ? (
                            <>
                                <Row justify={"start"} className="flex__start__start gap">
                                    <Col span={24} className="flex__start__start gap">
                                        <Image
                                            width={"50%"}
                                            preview={false}
                                            src={currentBook?.cover_url}
                                            alt="books[2]_image"
                                            fallback={imageFallback}
                                        />
                                        <div>
                                            <Typography.Title level={5}>{currentBook?.title}</Typography.Title>
                                            <Typography.Text>Số chương: {currentBook?.book?.total_chapters}</Typography.Text><br/>
                                            <Typography.Text>Số trang:{currentBook?.book?.total_pages}</Typography.Text>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <p>
                                            {t("author")}: {currentBook?.book?.author || t("updating")}
                                        </p>
                                        <p className="book_description">
                                            {t("description")}: {currentBook?.book?.description ?? t("updating")}
                                        </p>
                                    </Col>
                                    <Col span={24} className="flex__center__center gap-sm">
                                        <Button style={{ width: "100%" }} onClick={() => navigate(`/book/${currentBook.book_id}`)} type="primary" size="large">
                                            {t("continue_reading")}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <NoData imageWidth={100} text={t("no_books_read")} />
                        )}
                    </Col>
                </Row>
                {isLoading && <Loading />}
            </div>
        </Content>
    );
}

export default MyLibrary;
