import React, { useCallback } from "react";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { Button, Col, Divider, message, Modal, Row, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import Loading from "../../../components/Loading";
import { title } from "faker/lib/locales/az";
import CustomTestIcon from "../../../components/Icons/CustomTestIcon";
import CustomExerciseIcon from "../../../components/Icons/CustomExerciseIcon";
import { useTranslation } from "react-i18next";
import RatingByMark from "../../../components/RatingByMark";
import { fixedNumber } from "../../../utils/validation";
import { useNavigate } from "react-router-dom";
import CustomWarningIcon from "../../../components/Icons/CustomWarningIcon";
import { AASSIGNMENT } from "../../../apis/exercise";
import { confirmStartAssignment } from "../../../utils/exercises";

function Submitted(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let {
        data: assignments,
        isFetching,
        isError,
        refetch,
    } = useQuery(
        [`student_assignment_submitted`],
        async () => {
            const res = await handleFetchApi("get", "assignment/student-by-submitted?isSubmitted=true");
            console.log("res::", res);
            return res?.data;
        },
        {
            staleTime: 60000, // 1 minutes
            cacheTime: 60000,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        {
            title: "Tên bài kiểm tra/bài tập",
            dataIndex: "name",
            key: "name",
            render: (value, record) => (
                <div className="flex__start__center gap">
                    {record?.type === "TEST" ? <CustomTestIcon /> : <CustomExerciseIcon />} <Typography.Text>{record?.name}</Typography.Text>
                </div>
            ),
        },
        {
            title: "Loại",
            dataIndex: "type",
            key: "type",
            render: (value, record) => <Tag color={record?.type === "TEST" ? "#FFEB5B" : "#BBDAFF"}>{t(record?.type?.toLowerCase())}</Tag>,
            filters: [
                { text: "Bài Kiểm Tra", value: "TEST" },
                { text: "Bài tập", value: "NORMAL" },
            ],
            onFilter: (value, record) => record?.type === value,
        },
        {
            title: "Ghi chú",
            key: "note",
            dataIndex: "submittedAt",
            render: (value, record) => {
                const deadline = record?.deadlineAt;
                const submitted = record?.submittedAt;
                const isLate = moment(submitted).isAfter(moment(deadline));
                const status = isLate ? "red" : "green";
                const statusText = isLate ? "Nộp muộn" : "Đúng hạn";
                return <Tag color={status}>{statusText}</Tag>;
            },
        },
        {
            title: "Kết quả",
            key: "mark",
            dataIndex: "mark",
            render: (value) => (
                <div className="flex__start__start flex__column">
                    <span>
                        <Typography.Text type="danger">{fixedNumber(value)}</Typography.Text> / 10
                    </span>
                    <RatingByMark mark={value} />
                </div>
            ),
            sorter: (a, b) => a?.mark || 0 - b?.mark || 0,
        },
        {
            title: null,
            key: "actions",
            dataIndex: "actions",
            render: (value, record) => (
                <Space>
                    <Button type="link" href={`/assignment/${record?.id}/grading/${record?.gradingId}`}>
                        XEM KẾT QUẢ
                    </Button>
                    <Button type="link" disabled={record?.type === "TEST"} onClick={() => confirmStartAssignment({ assignment: record, navigate: navigate })}>
                        LÀM LẠI
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div className="loading_container">
            <br />
            <section>
                <Table pagination={{ pageSize: 8 }} dataSource={assignments} columns={columns} rowKey={(record) => record?.id} />
            </section>

            {isFetching && <Loading />}
        </div>
    );
}

export default Submitted;
