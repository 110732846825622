import React, { Fragment } from "react";
import HeaderDashboard from "../../components/HeaderDashboard";
import { HomeOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Row, Tabs } from "antd";
import ForMeTab from "./components/ForMeTab";
import StudentsTab from "./components/StudentsTab";
import { t } from "i18next";

function ActivateBook(props) {
    let navigate = useNavigate();

    const STUDENTS = [
        { label: "Kích hoạt sách", key: "for-me", children: <ForMeTab /> },
        // { label: "Cho học sinh", key: "for-student", children: <StudentsTab /> },
        // { label: "Cho một lớp", key: "for-class", children: <div></div> },
    ];

    return (
        <>
            <HeaderDashboard />
            <section id="p_activate" className="flex__start__center flex__column">
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t('activate_book_breadcrumb')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Row className="container" justify="center" align="middle">
                    <Tabs items={STUDENTS} />
                    {/* <ForMeTab /> */}
                </Row>
            </section>
        </>
    );
}

export default ActivateBook;
