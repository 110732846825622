import { Avatar, Button, Col, Row, Typography, Divider, Layout, Segmented } from "antd";
import React, { useContext, useState } from "react";
import avatarImg from "../../assets/images/avatar.png";
import { StoreContext } from "../../stores";
import { useQuery } from "@tanstack/react-query";
import { checkValidSesson, logout } from "../../utils";
import StudentActivities from "./components/StudentActivities";
import StudentComment from "./components/StudentComment";
import { useTranslation } from "react-i18next";
import StudentOverview from "./components/StudentOverview";
import CustomAddressIcon from "../../components/Icons/CustomAddressIcon";
import CustomSchoolIcon from "../../components/Icons/CustomSchoolIcon";
import CustomClassIconOutline from "../../components/Icons/CustomClassIconOutline";
import rp_commend from "../../assets/images/rp_commend.png";
import rp_remind from "../../assets/images/rp_remind.png";
import { RubyOutlined } from "@ant-design/icons";
import CustomWalletIcon from "../../components/Icons/CustomWalletIcon";

const { Content } = Layout;

function StudentProfile() {
    const { t } = useTranslation();
    const { me, setMe } = useContext(StoreContext);
    const [viewMode, setViewMode] = useState("overview");

    const {
        data: profile,
        isLoading,
        isError,
    } = useQuery(
        ["student_profile"],
        async () => {
            return checkValidSesson(me, setMe);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    const segmentedOptions = [
        { label: t("overview"), value: "overview" },
        { label: t("activities"), value: "activities" },
        { label: t("comment"), value: "comment" },
    ];

    const renderViewMode = () => {
        switch (viewMode) {
            case "overview":
                return <StudentOverview />;
            case "activities":
                return <StudentActivities me={me} />;
            case "comment":
                return <StudentComment />;
            default:
                return <StudentActivities me={me} />;
        }
    };

    return (
        <Content>
            <div className="page ">
                <Row className="flex__between__stretch" gutter={[24, 24]}>
                    <Col className="student_profile__info" xs={24} sm={24} md={24} lg={12} xl={8}>
                        <div className="card --padding --lg">
                            <div className="student_profile__base_info flex__center__center flex__column">
                                <Avatar src={avatarImg} size={100} />
                                <br/>
                                <Typography.Title level={4}>{me?.name}</Typography.Title>
                                <Typography.Text type="secondary">ID: {me?.username}</Typography.Text>
                            </div>

                            <div className="popover_profile__info flex__center__center flex__column">
                                <Typography.Text style={{ width: "100%", marginTop: 12 }} type="warning" className="flex__between__center">
                                    <span className="flex__start__center gap-sm">
                                        <CustomWalletIcon/> Point
                                    </span>
                                    <span>1758 điểm</span>
                                </Typography.Text>
                                <Typography.Text style={{ width: "100%", marginTop: 12 }} type="secondary" className="flex__between__center">
                                    <span className="flex__start__center gap-sm">
                                        <CustomAddressIcon /> Địa chỉ
                                    </span>
                                    <span>{me?.address ?? "Chưa có thông tin"} </span>
                                </Typography.Text>
                                <Typography.Text style={{ width: "100%", marginTop: 12 }} type="secondary" className="flex__between__center">
                                    <span className="flex__start__center gap-sm">
                                        <CustomSchoolIcon /> Trường
                                    </span>
                                    <span>{me?.schoolClasses[0]?.name ?? "Chưa có thông tin"}</span>
                                </Typography.Text>
                                <Typography.Text style={{ width: "100%", marginTop: 12 }} type="secondary" className="flex__between__center">
                                    <span className="flex__start__center gap-sm">
                                        <CustomClassIconOutline /> Email
                                    </span>
                                    <span>{me?.email ?? "Chưa có thông tin"} </span>
                                </Typography.Text>
                            </div>
                            <br />

                            <div className="card --padding --lg flex__between__center gap-sm" style={{ backgroundColor: "#D5F5DE", borderColor: "#8DEAA7" }}>
                                <div className="flex__start__start flex__column">
                                    <Typography.Title level={3} style={{ marginBottom: 0 }} type="success">
                                        3 lần
                                    </Typography.Title>
                                    <Typography.Text>
                                        được tuyên dương <br /> trong tháng này
                                    </Typography.Text>
                                </div>
                                <img style={{ width: 70 }} alt="content" src={rp_commend} />
                            </div>
                            <br />
                            <div className="card --padding --lg flex__between__center gap-sm" style={{ backgroundColor: "#FFE2E2", borderColor: "#FFD0D0" }}>
                                <div className="flex__start__start flex__column">
                                    <Typography.Title level={3} style={{ marginBottom: 0 }} type="danger">
                                        1 lần
                                    </Typography.Title>
                                    <Typography.Text>
                                        bị nhắc nhở <br /> trong tháng này
                                    </Typography.Text>
                                </div>
                                <img style={{ width: 70 }} alt="content" src={rp_remind} />
                            </div>
                            <br />
                            <div className="flex__center__center gap">
                                <Button type="primary" danger onClick={() => logout()} className="popover_profile__logout">
                                    Đăng Xuất
                                </Button>
                            </div>
                        </div>
                    </Col>
                    {/* <Col className="student_profile__details" xs={24} sm={24} md={24} lg={12} xl={16}>
                        <div className="card --padding --lg">
                            <Segmented style={{ marginBottom: 12 }} onChange={setViewMode} size="large" options={segmentedOptions} />

                            {renderViewMode()}
                        </div>
                    </Col> */}
                </Row>
            </div>
        </Content>
    );
}

export default StudentProfile;
