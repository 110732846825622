export const loginApi = `${process.env.REACT_APP_API_BASE_URL}/auth/sso/sign-in`;

export const AAUTH = {
    login: "/auth/sign-in",
    loginSSO: "/auth/sso/sign-in",
    getAccessToken: (session_id) => `/auth/sso/access_token?session_id=${session_id}`,
    forgotPassword: 'auth/forgot-password',
    checkEmail: username => `users/email?username=${username}`,
    setNewPassword: 'auth/reset-password',
    verifyEmail: 'auth/confirm-field'
};
