import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleFetchApi } from '../../../configs/apiRequest';
import { AAUTH } from '../../../apis/auth';
import { Modal, notification } from 'antd';
import CustomSuccessIcon from '../../../components/Icons/CustomSuccessIcon';
import CustomDangerIcon from '../../../components/Icons/CustomDangerIcon';

function VerifyEmail(props) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()

    // Retrieve the token and username from the query parameters
    const token = searchParams.get("token");
    const username = searchParams.get("username");

    const {
        isError,
        data,
        isLoading,
    } = useQuery(
        ["dashboard"],
        async () => {
            const res = await handleFetchApi("POST", AAUTH.verifyEmail, {
                username: username,
                token: token
            });
            if(res?.status === 'S0000') {
                return Modal.success({
                    title: 'Xác thực email thành công!',
                    content: 'Xác thực email giúp bạn dễ dàng lấy lại mật khẩu khi cần thiết!',
                    centered: true,
                    icon: <CustomSuccessIcon />,
                    onOk: () => navigate('/')
                })
                // setTimeout(() => {
                //     navigate('/')
                // }, 2000);
            }
            return Modal.error({
                title: 'Xác thực thất bại!',
                content: 'Liên kết này đã hết hạn, vui lòng thực hiện lại yêu cầu!',
                centered: true,
                icon: <CustomDangerIcon />,
                onOk: () => navigate('/')
            })
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    return isError && (
        <div>
            
        </div>
    );
}

export default VerifyEmail;