import React, { useState, useEffect, useRef, useContext } from "react";
import { Typography, notification } from "antd";
import moment from "moment";

import bee from "../../../assets/images/bee.png";
import house from "../../../assets/images/house.png";
import { ExerciseContext } from "../../../stores/exerciseStore";

const Timeout = ({ onSubmit }) => {
    const { selectedExercise, isDoing } = useContext(ExerciseContext);

    const [timeLeft, setTimeLeft] = useState(null);
    const endTimeRef = useRef(null);
    const timeout = selectedExercise.assignment?.timeDown;
    const startedAt = selectedExercise?.startedAt;
    useEffect(() => {
        if (timeout === null || !isDoing() || selectedExercise?.type === "exercise") return;

        if (!endTimeRef.current) {

            endTimeRef.current = moment(startedAt).add(timeout * 60, "seconds");
        }

        const timer = setInterval(() => {
            const now = moment();
            const duration = moment.duration(endTimeRef.current.diff(now));

            if (duration.asSeconds() <= 0) {
                clearInterval(timer);
                onSubmit();
                return;
            }

            setTimeLeft(duration);

            if (duration.asSeconds() === 180) {
                notification.warning({
                    message: "Time is running out!",
                    description: "You have 3 minutes left to complete the assignment.",
                    duration: 10,
                });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [timeout, startedAt, onSubmit, isDoing()]);

    if (timeout === null || !timeLeft) return <div></div>;

    const minutesLeft = Math.floor(timeLeft.asSeconds() / 60);
    const secondsLeft = Math.floor(timeLeft.asSeconds() % 60);

    return (
        <>
            <div className="custom_progress">
                <div style={{ width: `${100 - (timeLeft.asSeconds() / (timeout * 60)) * 100}%` }} className="line">
                    <img className="bee" src={bee} alt="bee" />
                </div>
                <img className="house" src={house} alt="house" />
            </div>
            <Typography.Text className="timeout" type="danger">
                {String(minutesLeft).padStart(2, "0")}:{String(secondsLeft).padStart(2, "0")}
            </Typography.Text>
        </>
    );
};

export default Timeout;
