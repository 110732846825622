import { Table, Button, Col, Typography, Select, Modal, Input, notification } from "antd";
import { useQuery, useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const CourseDetail = ({ course = {} }) => {
    const [classes, setClasses] = useState([]);
    const [teacherId, setTeacherId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const { data: classData, isLoading: isLoadingClasses } = useQuery(["classes"], () => handleFetchApi("GET", `/class`), {
        enabled: !!course.id,
    });

    const addTeacherToCourse = useMutation(async (teacherId) => {
        const response = await handleFetchApi("POST", `/course/teacher`, {
            course_id: course.id,
            teacher_id: teacherId,
        });
        if (response?.status === "status") notification.success({ message: "Thành công!" });
        setIsModalVisible(false);
    });

    const columns = [
        {
            title: "Class Name",
            dataIndex: "name",
            key: "name",
            render: (_, record) => `Lớp ${record?.grade} ${record?.name?.replace("Lớp ", "")}`,
        },
        {
            title: "Trường",
            dataIndex: ["school", "name"],
            key: "teacherName",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <div className="flex__start__center gap-sm">
                    <Button type="link" onClick={() => navigate(`/course/${course.id}/class/${record.id}`)}>
                        Xem
                    </Button>
                    <Button
                        type="link"
                        danger
                        onClick={async () => {
                            // console.log("record::", course);
                            // // const res = await handleFetchApi('post', 'course/class/remove', {
                            // //     course_teacher_id: courseTeacherId,
                            // //     class_id: cls.id,
                            // // })
                        }}
                    >
                        Xóa
                    </Button>
                </div>
            ),
        },
    ];

    const onChange = async (value) => {
        const response = await handleFetchApi("get", `/course/teacher/${value}`);
        setTeacherId(value);
        setClasses(response?.data[0]?.classes);
    };

    const onSearch = (value) => {
        console.log("search:", value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const addClassToTeacher = async (value) => {
        const response = await handleFetchApi("post", `course/${course.id}/teacher/${teacherId}`, {
            class_ids: [value],
        });

        if (response?.status === "S0000") notification.success({ message: "Thành công!" });
    };

    return (
        <Col span={18}>
            <div className="flex__between__center ">
                <Typography.Title level={4}>{course.name} </Typography.Title>
                <div className="flex__center__center gap-sm">
                    <Select
                        style={{ width: 200 }}
                        showSearch
                        placeholder="Select a teacher"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={course?.users?.map((u) => ({ label: u?.name, value: u?.id }))}
                    />

                    <Button onClick={showModal} type="primary" icon={<PlusOutlined />}></Button>
                </div>
            </div>
            <Modal title="Select a Teacher" open={isModalVisible} onCancel={handleCancel}>
                <Input size="large" onPressEnter={(e) => addTeacherToCourse.mutate(e.target?.value)} placeholder="Teacher's ID" />
            </Modal>
            {teacherId && (
                <div className="flex__end__center gap" style={{ margin: "12px 0" }}>
                    <Select
                        style={{ width: 300 }}
                        showSearch
                        placeholder="Select a class"
                        optionFilterProp="children"
                        onChange={addClassToTeacher}
                        onSearch={onSearch}
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={isLoadingClasses ? [] : classData?.data?.map((c) => ({ label: `${c.grade}${c.name} - ${c.school?.name}`, value: c.id }))}
                    />
                    <Button type="primary" icon={<PlusOutlined />}>
                        Thêm
                    </Button>
                </div>
            )}
            <Table dataSource={classes} columns={columns} rowKey="id" />
        </Col>
    );
};

export default CourseDetail;
