import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import BookProvider from "../stores/bookStore";
import ExerciseProvider from "../stores/exerciseStore";
import Loading from "../components/Loading";
import Root from "./root";
import Book from "../views/Book";
import ErrorPage from "../views/Error";
import ExerciseMode from "../views/ExerciseMode";
import ManageCourses from "../views/Manage/ManageCourses";
import ActivateBook from "../views/ActivateBook";
import ScoreReport from "../views/ScoreReport";
import MyLibrary from "../views/MyLibrary";
import Homepage from "../views/Home";
import ManageAssignment from "../views/ManageAssignment";
import VerifyEmail from "../views/Authentication/VerifyEmail";
import StudentAssignment from "../views/StudentAssignment";
import StudentReport from "../views/StudentReport";
import AdminReport from "../views/Admin/Report";
import ManageClasses from "../views/Manage/ManageClasses";
import ClassesCourse from "../views/Manage/ClassesCourse";
import StudentProfile from "../views/Student/Profile";
import SuggestionBook from "../views/SuggestionBook";
import ManageSchool from "../views/Admin/ManageSchool";
import ManageCourse from "../views/Admin/ManageCourse";
import NotFound from "../views/Error/NotFound";
import Login from "../views/Authentication/Login";
import ForgotPassword from "../views/Authentication/ForgotPassword";
import SetNewPassword from "../views/Authentication/SetNewPassword";
import SessionValidator from "../views/Authentication/SessionValidator";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "",
                element: <Homepage />,
            },
            {
                path: "my_library",
                element: <MyLibrary />,
            },
            {
                path: "manage/assignment/:assignment_id",
                element: <ManageAssignment />,
            },
            {
                path: "exercises",
                element: <StudentAssignment />,
            },
            {
                path: "report",
                element: <StudentReport />,
            },
            {
                path: "admin/report",
                element: <AdminReport />,
            },
            {
                path: "student/profile",
                element: <StudentProfile />,
            },
            {
                path: "/suggestion-book",
                element: <SuggestionBook />,
            },
            {
                path: "/admin/schools",
                element: <ManageSchool />,
            },
            {
                path: "/admin/courses",
                element: <ManageCourse />,
            },
            {
                path: "/course/:courseId/class/:classId",
                element: <Homepage />,
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: "/login",
        element: <Login />,
        // errorElement: <ErrorPage />,
    },
    {
        path: "/forgot_password",
        element: <ForgotPassword />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/verify_email",
        element: <VerifyEmail />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/set_new_password",
        element: <SetNewPassword />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/book/:book_id",
        element: (
            <BookProvider>
                <Suspense fallback={<Loading />}>
                    <Book />
                </Suspense>
            </BookProvider>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageCourses />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    // manage assignment
    {
        path: "/assignment/:exercise_id/grading/:grading_id",
        element: (
            <ExerciseProvider>
                <Suspense fallback={<Loading />}>
                    <ExerciseMode />
                </Suspense>
            </ExerciseProvider>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/exercise/:exercise_id",
        element: (
            <ExerciseProvider>
                <Suspense fallback={<Loading />}>
                    <ExerciseMode />
                </Suspense>
            </ExerciseProvider>
        ),
        // errorElement: <ErrorPage />,
    },
    // {
    //     path: "/assignment/:exercise_id/grading/:student_id",
    //     element: (
    //         <ExerciseProvider>
    //             <Suspense fallback={<Loading />}>
    //                 <ExerciseMode />
    //             </Suspense>
    //         </ExerciseProvider>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    
    // {
    //     path: "/manage/courses",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <ManageCourses />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/manage/school",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <ManageSchool />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/manage/school/:school_id",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <ManageSchool />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/manage/school-by-admin",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <ManageSchoolByAdmin />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/manage/students",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <ManageStudents />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    {
        path: "/manage/course/:course_id",
        element: (
            <Suspense fallback={<Loading />}>
                <ClassesCourse />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/course/:course_id/class/:class_id",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageClasses />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    // {
    //     path: "/student/courses",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <StudentCourses />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/student/TEST",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <StudentAssignments />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/student/NORMAL",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <StudentAssignments />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/student/profile",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <StudentProfile />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    {
        path: "/activate-book",
        element: (
            <Suspense fallback={<Loading />}>
                <ActivateBook />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },

    // Exercise
    // {
    //     path: "/exercise/:exercise_id",
    //     element: (
    //         <ExerciseProvider>
    //             <Suspense fallback={<Loading />}>
    //                 <ExerciseMode />
    //             </Suspense>
    //         </ExerciseProvider>
    //     ),
    //     // errorElement: <ErrorPage />,
    // },
    // {
    //     path: "/assignment/:exercise_id",
    //     element: (
    //         <ExerciseProvider>
    //             <Suspense fallback={<Loading />}>
    //                 <ExerciseMode />
    //             </Suspense>
    //         </ExerciseProvider>
    //     ),
    //     // errorElement: e => {
    //     //     console.log('e', e);
    //     //     return <ErrorPage />
    //     // },
    // },
    {
        path: "/score_report",
        element: (
            <ExerciseProvider>
                <Suspense fallback={<Loading />}>
                    <ScoreReport />
                </Suspense>
            </ExerciseProvider>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/error-page",
        element: <ErrorPage />,
    },
    {
        path: "/session/:session_id",
        element: <SessionValidator />,
    },
]);
