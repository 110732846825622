import React, { useContext } from "react";
import { StoreContext } from "../../stores";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { Button, Typography } from "antd";
import Loading from "../../components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import CustomClassIcon from "../../components/Icons/CustomClassIcon";
import { useTranslation } from "react-i18next";

function TeacherSidebar() {
    const { t } = useTranslation();
    const { me, setSelectedClass, selectedClass, setBreadcrumb } = useContext(StoreContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { data: teacherCourses, isLoading } = useQuery(
        ["teacherCourses", me?.id],
        async () => {
            const res = await handleFetchApi("get", `course/teacher/${me?.id}`);
            if (pathname === "/") {
                const firstClass = { ...res?.data[0]?.classes[0], courseId: res?.data[0]?.course?.id };
                setSelectedClass(firstClass);
            }
            return res?.data;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: !!me?.id,
        }
    );

    if (!teacherCourses) return null;

    const handleClassClick = (cls, courseId) => {
        const select = { ...cls, courseId };
        setSelectedClass(select);
        navigate("/");
        setBreadcrumb({ label: "dashboard" });
    };

    return (
        <div className="flex__start__start flex__column">
            <br />
            <Typography.Title className="app_sidebar__label" level={5}>
                {t("classManagement")}
            </Typography.Title>
            <div style={{maxHeight: '50dvh', overflow: 'auto'}}>
                {teacherCourses?.map((tc) =>
                    tc?.classes?.map((cls) => (
                        <Button
                            onClick={() => handleClassClick(cls, tc?.course?.id)}
                            key={`${tc.course.id}-${cls.id}`}
                            className={`flex__start__center ${selectedClass?.id === cls?.id ? "active" : ""}`}
                            type="text"
                            icon={<CustomClassIcon />}
                        >
                            <span>
                                Lớp {cls?.grade} {cls?.name?.replace("Lớp ", "")}
                                <br />
                                {cls?.school?.name}
                            </span>
                        </Button>
                    ))
                )}
            </div>
            {isLoading && <Loading />}
        </div>
    );
}

export default TeacherSidebar;
