import { Button, Descriptions, Modal, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { getDataToSubmit } from "../utils/submit";
import { ExerciseContext } from "../../../stores/exerciseStore";
import { StoreContext } from "../../../stores";
import { handleFetchApi } from "../../../configs/apiRequest";
import { fixedNumber } from "../../../utils/validation";
import { useTranslation } from "react-i18next";
import RatingByMark from "../../../components/RatingByMark";
import moment from "moment";
import { ExerciseModeEnums } from "../../../configs/enums";
import CustomWarningIcon from "../../../components/Icons/CustomWarningIcon";
import Timeout from "./Timeout";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";

function SubHeader(props) {
    const { t } = useTranslation();
    const { me, exerciseChildren } = useContext(StoreContext);
    const { selectedExercise, setSelectedExercise, choice, matched, fill, record, writing, dragList, setExerciseResult, isDoing, exerciseResult } =
        useContext(ExerciseContext);

    const submitAssignment = async () => {
        const dataToSubmit = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });
        const result = await handleFetchApi("POST", `assignment/${selectedExercise?.id}/submit`, dataToSubmit);
        if (result?.status === "AS0006") {
            Modal.error({
                title: t("ex_submit_err"),
                content: t("ex_submit_err_content"),
            });
        }
        if (result?.status === "S0000") {
            const { exerciseResult, evaluation, startedAt, submittedAt } = result?.data;
            let comment = evaluation?.comment;
            Modal.success({
                title: "Hoàn thành bài kiểm tra",
                okText: "ĐÓNG",
                icon: <RatingByMark mark={evaluation?.mark} />,
                footer: null,
                className: `exercise-result --centered ${
                    evaluation?.mark < 5 ? "low-score" : evaluation?.mark >= 5 && evaluation?.mark <= 8 ? "medium-score" : "high-score"
                }`,
                centered: true,
                content: (
                    <div className="assignment-result">
                        <Typography.Title level={3} className="assignment-result__mark">
                            {fixedNumber(evaluation?.mark)}
                            <small> / 10</small>
                        </Typography.Title>

                        <Typography.Text className="text--left">
                            Nhận xét: <b>{comment ?? "Chưa có nhận xét"}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            Bắt đầu: <b>{moment(startedAt).format("DD/MM/YYYY HH:mm")}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            Nộp bài: <b>{moment(submittedAt).format("DD/MM/YYYY HH:mm")}</b>
                        </Typography.Text>

                        {!evaluation && (
                            <Typography.Text type="danger" style={{ textAlign: "center" }}>
                                Bài tập hiện chưa thể tính điểm. Hãy chờ giáo viên của bạn kiểm tra nhé !
                            </Typography.Text>
                        )}
                    </div>
                ),
            });
            const exerciseResultGroupById = new Map(exerciseResult.map((result) => [result.item_id, result]));
            setExerciseResult(exerciseResultGroupById);
            setSelectedExercise((prev) => ({ ...prev, exerciseMode: ExerciseModeEnums.REVIEW }));
        }
    };

    const submitExercise = async () => {
        const dataToSubmit = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });

        const result = await handleFetchApi("POST", `exercise-results`, dataToSubmit[0]);

        if (result?.status === "AS0006") {
            Modal.error({
                title: t("ex_submit_err"),
                content: t("ex_submit_err_content"),
            });
        }
        if (result?.status === "S0000") {
            const { evaluation, startedAt, submittedAt } = result?.data;
            let comment = evaluation?.comment;
            Modal.success({
                title: "Hoàn thành bài kiểm tra",
                okText: "ĐÓNG",
                icon: <RatingByMark mark={evaluation?.mark} />,
                footer: null,
                className: `exercise-result --centered ${
                    evaluation?.mark < 5 ? "low-score" : evaluation?.mark >= 5 && evaluation?.mark <= 8 ? "medium-score" : "high-score"
                }`,
                centered: true,
                content: (
                    <div className="assignment-result">
                        <Typography.Title level={3} className="assignment-result__mark">
                            {fixedNumber(evaluation?.mark)}
                            <small> / 10</small>
                        </Typography.Title>

                        <Typography.Text className="text--left">
                            Nhận xét: <b>{comment ?? "Chưa có nhận xét"}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            Bắt đầu: <b>{moment(startedAt).format("DD/MM/YYYY HH:mm")}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            Nộp bài: <b>{moment(submittedAt).format("DD/MM/YYYY HH:mm")}</b>
                        </Typography.Text>

                        {!evaluation && (
                            <Typography.Text type="danger" style={{ textAlign: "center" }}>
                                Bài tập hiện chưa thể tính điểm. Hãy chờ giáo viên của bạn kiểm tra nhé !
                            </Typography.Text>
                        )}
                    </div>
                ),
            });
            const exerciseResultGroupById = new Map([result?.data]?.map((result) => [result.item_id, result]));
            setExerciseResult(exerciseResultGroupById);
            setSelectedExercise((prev) => ({ ...prev, exerciseMode: ExerciseModeEnums.REVIEW }));
        }
    };

    const handleSubmit = () => {
        let alertText = "Cố gắng hoàn thành tất cả bài tập trước khi nộp nhé!";
        Modal.confirm({
            title: "Bạn có thực sự muốn nộp bài?",
            content: alertText,
            okText: "NỘP BÀI",
            cancelText: "Quay lại",
            icon: <CustomWarningIcon />,
            centered: true,
            onOk: () => {
                console.log(";selectedExercise?.type", selectedExercise?.type);
                if (selectedExercise?.type === "exercise") submitExercise();
                else submitAssignment();
            },
        });
    };

    const autoSave = async () => {
        const dataToSave = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });
        if (dataToSave) {
            await handleFetchApi("POST", `assignment/${selectedExercise?.id}/save`, dataToSave);
        }
    };

    useEffect(() => {
        if (selectedExercise?.type !== "exercise") {
            const interval = setInterval(() => {
                isDoing() && autoSave();
            }, 30000); // 30 seconds

            return () => clearInterval(interval); // Cleanup on unmount
        }
    }, [selectedExercise, me, choice, record, fill, matched, writing, dragList, exerciseChildren]);

    if (selectedExercise?.submittedAt || !isDoing()) return;
    return (
        <div className="flex__between__center exercise_subheader">
            <Timeout onSubmit={submitAssignment} />
            <Button type="primary" size="large" onClick={handleSubmit}>
                NỘP BÀI
            </Button>
        </div>
    );
}

export default SubHeader;
