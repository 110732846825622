import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../stores";
import { useTranslation } from "react-i18next";

function ButtonExit({size = 'middle'}) {
    const t = useTranslation()
    const { setMedia } = useContext(StoreContext);

    const backToBook = () => {
        setMedia();
        let lastUrl = window.location.search.split("=")[1] ?? '';
        
        if (lastUrl?.includes("/login")) {
            window.location.href = "/";
        } else {
            window.history.go(-1); // Use go(-1) to ensure it goes back to the previous page
        }
        return false;
    };

    const warning = () => {
        Modal.confirm({
            title: t("alrert_exit_exercise_title"),
            content: t("alrert_exit_exercise_content"),
            onOk() {
                backToBook();
            },
            okText: t("alrert_exit_exercise_ok"),
            cancelText: t("back"),
            okType: "danger",
            centered: true,
        });
    };


    return <Button size={size} onClick={() => backToBook()} icon={<CloseOutlined />} type="primary" danger ></Button>;
}

export default ButtonExit;
