import { Avatar, Button, Modal, Typography } from "antd";
import React, { useContext, useState } from "react";
import avatarImg from "../../assets/images/avatar.png";
import { StoreContext } from "../../stores";
import { t } from "i18next";
import { logout } from "../../utils";
import CustomAddressIcon from "../Icons/CustomAddressIcon";
import CustomSchoolIcon from "../Icons/CustomSchoolIcon";
import CustomClassIconOutline from "../Icons/CustomClassIconOutline";
import CustomPenIcon from "../Icons/CustomPenIcon";
import ModalUpdateName from "./ModalUpdateName";
import ModalUpdateAddress from "./ModalUpdateAddress";
import ModalUpdatePassword from "./ModalUpdatePassword";
import ModalUpdateEmail from "./ModalUpdateEmail";
import { ExclamationCircleTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import CustomDangerIcon from "../Icons/CustomDangerIcon";

function PopoverProfile(props) {
    const { me } = useContext(StoreContext);
    const [openModal, setOpenModal] = useState();

    return (
        <>
            <div className="popover_profile flex__center__center flex__column gap">
                <div className="popover_profile_base flex__center__center flex__column">
                    <Avatar src={avatarImg} />
                    <Typography.Title level={4}>
                        {me?.name}{" "}
                        <span onClick={() => setOpenModal("NAME")}>
                            <CustomPenIcon />
                        </span>
                    </Typography.Title>
                    <Typography.Text type="secondary">ID: {me?.username}</Typography.Text>
                </div>
                <div className="flex__center__center gap">
                    <Button className="btn-white" onClick={() => setOpenModal("PASSWORD")}>
                        {t("update_pwd")}
                    </Button>
                    <Button type="ghost" danger onClick={() => logout()} className="popover_profile__logout">
                        Đăng Xuất
                    </Button>
                </div>
                <div className="popover_profile__info flex__center__center flex__column">
                    <Typography.Text type="secondary" className="flex__between__center">
                        <span className="flex__start__center gap-sm">
                            <CustomAddressIcon /> Địa chỉ
                        </span>
                        <span>
                            {me?.address ?? "Chưa có thông tin"}{" "}
                            <span onClick={() => setOpenModal("ADDRESS")}>
                                <CustomPenIcon />
                            </span>
                        </span>
                    </Typography.Text>
                    {me?.role === "ROLE_USER" && (
                        <Typography.Text type="secondary" className="flex__between__center">
                            <span className="flex__start__center gap-sm">
                                <CustomSchoolIcon /> Lớp
                            </span>
                            <span>
                                {me?.schoolClasses[0]?.grade} {me?.schoolClasses[0]?.name?.replace("Lớp ", "")}
                            </span>
                        </Typography.Text>
                    )}
                    <Typography.Text type="secondary" className="flex__between__center">
                        <span className="flex__start__center gap-sm">
                            <CustomClassIconOutline /> Email
                        </span>
                        <span>
                            {me?.email && me?.email_verified ? (
                                me?.email
                            ) : (
                                <>
                                    <Button type="link" danger onClick={() => setOpenModal("EMAIL")}>
                                        + Thêm một email{" "}
                                    </Button>{" "}
                                    &nbsp;
                                    <ExclamationCircleTwoTone twoToneColor={"#F43F5E"} />
                                </>
                            )}
                        </span>
                    </Typography.Text>
                </div>
            </div>
            <ModalUpdateName open={openModal === "NAME"} close={() => setOpenModal(null)} />
            <ModalUpdateAddress open={openModal === "ADDRESS"} close={() => setOpenModal(null)} />
            <ModalUpdatePassword open={openModal === "PASSWORD"} close={() => setOpenModal(null)} />
            <ModalUpdateEmail open={openModal === "EMAIL"} close={() => setOpenModal(null)} />
        </>
    );
}

export default PopoverProfile;
