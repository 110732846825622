import { Button, Divider, Drawer, Segmented, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ChartOnlineTime from "./ChartOnlineTime";
import Loading from "../../../components/Loading";
import StudentScore from "./StudentScore";
import { useTranslation } from 'react-i18next';
import StudentDetailSummary from "./StudentDetailSummary";
import StudentChartSkill from "../../../components/StudentChartSkill";

function DrawerStudentDetail(props) {
    const { student, onClose } = props;
    const [viewMode, setViewMode] = useState("common");
    const { t } = useTranslation();
    useEffect(() => {
        // Effect logic, if any

        return () => {
            // Cleanup logic
            // Reset state or perform any other necessary cleanup
            setViewMode("common");
        };
    }, []); // Add dependencies as needed

    return (
        <Drawer
            className="student_detail"
            width={600}
            size="default"
            open={!!student?.user_name}
            onClose={() => {
                onClose();
                setViewMode("common");
            }}
            title={t("details")}
        >
            <div className="flex__between__center">
                <Typography.Title level={5}>{t("student")}</Typography.Title>
                <div className="flex__end__end flex__column">
                    <Typography.Text strong>{student?.student_name}</Typography.Text>
                    <Typography.Text type="secondary">{t("id")}: {student?.user_name}</Typography.Text>
                </div>
            </div>
            <br />
            <Segmented
                value={viewMode}
                onChange={(value) => setViewMode(value)}
                size="large"
                options={[
                    {
                        label: t("general_information"),
                        value: "common",
                    },
                    {
                        label: t("test_details"),
                        value: "score",
                    },
                ]}
            />
            <Divider />
            {viewMode === "common" && (
                <>
                    <Typography.Title level={5}>{t("general_information")}</Typography.Title>
                    <div className="flex__between__center">
                        <Typography.Text>{t("date_of_birth")}: </Typography.Text>
                        <Typography.Text>{t("updating")}</Typography.Text>
                    </div>
                    <div className="flex__between__center">
                        <Typography.Text>{t("email")}: </Typography.Text>
                        <Typography.Text>{t("updating")}</Typography.Text>
                    </div>
                    <div className="flex__between__center">
                        <Typography.Text>{t("courses")}: </Typography.Text>
                        <Typography.Text>{t("updating")}</Typography.Text>
                    </div>
                    <Divider />
                    <section className="student_radar">
                        <StudentChartSkill studentId={student?.user_id}/>
                        <Button className="btn-white" size="large" onClick={() => setViewMode('score')}>
                            {t("details").toUpperCase()}
                        </Button>
                    </section>
                    {/* <StudentDetailSummary studentId={student?.user_id} /> */}
                    {/* <Divider />
                    <section>
                        <Typography.Title level={5}>{t("activities")}</Typography.Title>
                        <ChartOnlineTime studentId={student?.user_id} />
                    </section> */}
                </>
            )}
            {viewMode === "score" && <StudentScore studentId={student?.user_id} />}
        </Drawer>
    );
}

export default DrawerStudentDetail;

