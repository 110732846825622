import { Typography } from "antd";
import React from "react";

const configsLocationTemplate = {
    name: {
        top: 17,
        left: 34,
    },
    className: {
        top: 20,
        left: 24,
    },
    schoolName: {
        top: 20,
        left: 53,
    },
    scoreDetail: {
        score: {
            top: 36.3,
            left: 78.5,
            color: "#3B8AFF",
            order: 5,
            size: 24,
        },
        reading_score: {
            top: 36.3,
            left: 29.3,
            width: 20,
            order: 1,
        },
        writing_score: {
            top: 36.3,
            left: 42,
            order: 2,
        },
        listening_score: {
            top: 36.3,
            left: 54,
            order: 3,
        },
        speaking_score: {
            top: 36.3,
            left: 66.5,
            order: 4,
        },
    },
    level: {
        top: 45.2,
        left: 78.2,
        style: "italic",
        size: 18,
    },
    ielts: {
        top: 50.4,
        left: 79,
        style: "italic",
        size: 18,
    },
    group: {
        top: 55.9,
        left: 79,
        style: "italic",
        size: 18,
    },
};

function ScoreFiller({ data = {} }) {
    const renderData = () => {
        return Object.keys(data).map((key) => {
            if (key === "scoreDetail") {
                return (
                    <div className="flex__around__center" style={{ position: "absolute", top: "36.3%", left: "25%", width: "60.5%" }}>
                        {Object.keys(data[key]).map((detailKey) => {
                            const template = configsLocationTemplate[key][detailKey];
                            return (
                                <Typography.Title
                                    level={4}
                                    style={{
                                        margin: 0,
                                        width: "20%",
                                        textAlign: "center",
                                        color: template.color || undefined,
                                        order: template.order,
                                        fontSize: template?.size ?? 22,
                                    }}
                                >
                                    {data[key][detailKey]}
                                </Typography.Title>
                            );
                        })}
                    </div>
                );
            } else {
                const template = configsLocationTemplate[key];
                return (
                    <Typography.Title
                        level={4}
                        style={{
                            position: "absolute",
                            margin: 0,
                            top: `${template.top}%`,
                            left: `${template.left}%`,
                            color: template.color || undefined,
                            fontStyle: template?.style ?? "normal",
                            fontSize: template?.size ?? 22,
                        }}
                    >
                        {data[key]}
                    </Typography.Title>
                );
            }
        });
    };

    return <>{renderData()}</>;
}

export default ScoreFiller;
