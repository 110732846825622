import { useQuery } from "@tanstack/react-query";
import { Modal, notification, Steps } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { handleFetchApi } from "../../../configs/apiRequest";
import SelectBook from "./SelectBook";
import SelectExercise from "./SelectExercise";

const { Step } = Steps;

function ModalSelectBook({ isOpenModal, setIsOpenModal, selectedBook, setSelectedBook, selectedItems, setSelectedItems }) {
    const { t } = useTranslation();
    const [current, setCurrent] = useState(selectedBook ? 1 : 0);

    const steps = [
        { title: t('select_book'), content: <SelectBook selectedItems={selectedItems} setSelectedBook={setSelectedBook} selectedBook={selectedBook} /> },
        { title: t('select_exercise'), content: <SelectExercise selectedItems={selectedItems} setSelectedItems={setSelectedItems} selectedBook={selectedBook} /> },
    ];

    useEffect(() => {
        if (!selectedBook) setCurrent(0);
    }, [selectedBook]);

    const handleCancel = useCallback(() => {
        if (current === 0) {
            setIsOpenModal(false);
        } else {
            setCurrent(0);
        }
    }, [current, setIsOpenModal]);

    const handleOk = useCallback(() => {
        if (current === 0) {
            if (!selectedBook) {
                notification.warning({
                    description: t('please_select_book'),
                });
                return;
            }
            setCurrent(1);
        } else {
            setIsOpenModal(false);
        }
    }, [current, selectedBook, setIsOpenModal, t]);

    return (
        <Modal
            width="90dvw"
            title={t('select_exercise_from_library')}
            open={isOpenModal}
            onCancel={handleCancel}
            okText={current === 0 ? t('next') : t('finish_selecting_exercise')}
            cancelText={current === 0 ? t('cancel') : t('back')}
            okButtonProps={{
                size: "large",
            }}
            cancelButtonProps={{ size: "large", type: "primary", className: "btn-white" }}
            onOk={handleOk}
        >
            <Steps items={steps} current={current} />
            {steps[current].content}
        </Modal>
    );
}

export default ModalSelectBook;
