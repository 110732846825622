import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ScoreFiller from "./ScoreFiller";
// Core viewer

const PdfViewer = () => {

    const renderPage = (props) => (
        <>
            {props.canvasLayer.children}
            {props.pageIndex === 0 && (
                <ScoreFiller
                    data={{
                        name: "Monochrome",
                        className: "Dev",
                        schoolName: "Pharos",
                        scoreDetail: {
                            score: 112,
                            reading_score: 110,
                            listening_score: 104,
                            speaking_score: 120,
                            writing_score: 113,
                        },
                        ielts: 3.0,
                        level: 'A1',
                        group: 1
                    }}
                />
            )}
            {props.annotationLayer.children}
            {props.textLayer.children}
        </>
    );

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={"/template.pdf"} renderPage={renderPage} defaultScale={"PageWidth"} />
        </Worker>
    );
};

export default PdfViewer;
