import { useMutation } from "@tanstack/react-query";
import { DatePicker, Form, Input, message, Modal, notification, TimePicker } from "antd";
import React from "react";
import { disabledDate } from "../../../utils";
import moment from "moment";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useTranslation } from 'react-i18next';

function ModalUpdateAssignment(props) {
    const { open, close, refetch, assignment } = props;
    const { t } = useTranslation();

    const updateAssignment = useMutation({
        mutationFn: (values) => {
            message.loading(t("updating_information"));
            const date = moment(values?.date).format("YYYY-MM-DD");
            const time = moment(values?.time).format("HH:mm");
            const deadline_at = `${date} ${time}:00`;
            if (deadline_at?.includes('Invalid date')) {
                return notification.error({ message: t("missing_submission_deadline") });
            }
            delete values.date
            delete values.time
            const data = { ...values, deadline_at: deadline_at }
            if(!assignment.deadlineAt) delete data.deadline_at
            return handleFetchApi("PUT", `assignment/${assignment?.id}`, data);
        },
        onSuccess: (response) => {
            message.destroy();
            if (response?.status !== "S0000") {
                return;
            }
            notification.success({ message: t("update_information_success") });
            refetch();
            close();

            return;
        },
    });

    return (
        <Modal
            open={open}
            width={500}
            title={t("edit_information")}
            okText={t("save")}
            cancelText={t("cancel")}
            onCancel={close}
            okButtonProps={{ form: "updateAssignment", key: "submit", htmlType: "submit" }}
        >
            <Form
                id="updateAssignment"
                requiredMark={false}
                layout="vertical"
                name="update_password"
                onFinish={(values) => updateAssignment.mutate(values)}
                autoComplete="off"
            >
                <Form.Item label={t("name")} name="name">
                    <Input type="text" size="large" defaultValue={assignment?.name} />
                </Form.Item>
                {assignment?.deadlineAt && (
                    <>
                        <Form.Item label={t("submission_time")} name="time">
                            <TimePicker style={{ width: "100%" }} popupClassName="fixed-picker" size="large" format="HH:mm" />
                        </Form.Item>
                        <Form.Item label={t("submission_deadline")} name="date">
                            <DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} size="large" disabledDate={disabledDate} />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default ModalUpdateAssignment;
