import { Input, message, Modal, notification, Typography } from "antd";
import React, { useContext, useState } from "react";
import { debounce } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { StoreContext } from "../../stores";
import Loading from "../Loading";

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

function ModalUpdateEmail(props) {
    const { open, close } = props;
    const { setLoading, loading, setMe, me } = useContext(StoreContext);

    const [name, setName] = useState();

    const updateName = useMutation({
        mutationFn: (values) => {
            if (!isValidEmail(name)) {
                message.error("Vui lòng nhập đúng định dạng email!");
                return Promise.reject("Invalid email format");
            }
            message.loading("Đang cập nhật thông tin ...");
            setLoading(true);
            return handleFetchApi("POST", "auth/add-email", { email: name, callbackUrl: "https://staging.app.cloudbook.vn/verify_email" });
        },
        onSuccess: (response) => {
            message.destroy();
            if (response?.status !== "S0000") {
                setLoading(false);
                message.error("Có lỗi xảy ra. Vui lòng thử lại sau!");
                return;
            }
            notification.success({
                message: "Cập nhật thành công",
                description: "Vui lòng kiểm tra email mà bạn đã đăng ký.",
            });
            close();
            setLoading(false);
            return;
        },
        onError: (error) => {
            setLoading(false);
            if (error === "Invalid email format") {
                // Error already displayed
            } else {
                message.error("Có lỗi xảy ra. Vui lòng thử lại sau!");
            }
        },
    });

    return (
        <Modal zIndex={1033} open={open} width={500} title="Cập nhật Email" okText="LƯU" cancelText="HỦY" onCancel={close} onOk={updateName.mutate}>
            <Typography.Text>Email của bạn</Typography.Text>
            <Input style={{ marginBottom: 12 }} size="large" onChange={debounce((e) => setName(e?.target?.value))} />

            <Typography.Text style={{fontStyle: 'italic'}} type="secondary">
                Thêm một email sẽ giúp bạn lấy lại mật khẩu khi cần. <br /> Chúng tôi sẽ gửi cho bạn một email để xác nhận thông tin.
            </Typography.Text>
            {loading && <Loading />}
        </Modal>
    );
}

export default ModalUpdateEmail;
