import React, { useContext } from "react";
import { Row, Col, Typography, Image, Input } from "antd";
import rp_content from "../../assets/images/rp_content.png";
import rp_dis from "../../assets/images/rp_dis.png";
import rp_study from "../../assets/images/rp_study.png";
import rp_commend from "../../assets/images/rp_commend.png";
import rp_remind from "../../assets/images/rp_remind.png";
import { CommentOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { StoreContext } from "../../stores";

function Report({ reportData = {}, onComment }) {
    const { me } = useContext(StoreContext);
    return (
        <Row gutter={[16, 16]} className="teacher_report" align="stretch">
            <Col span={15}>
                <div className="report-section card --padding --no-border report-content">
                    <Typography.Title level={5}>Nội dung học tập</Typography.Title>
                    <div className="flex__start__center gap">
                        <img alt="content" src={rp_content} />
                        <Typography.Text>
                            {reportData?.content?.split("\n").map((item, index) => (
                                <span key={index}>
                                    {item}
                                    {index < reportData.content.split("\n").length - 1 && <br />}
                                </span>
                            ))}
                        </Typography.Text>
                    </div>
                </div>
            </Col>
            <Col span={9}>
                <div className="report-section card --padding --no-border report-status">
                    <Typography.Title level={5}>Tình hình</Typography.Title>
                    <div className="flex__start__center gap" style={{ marginBottom: 24 }}>
                        <img alt="content" src={rp_dis} />
                        <Typography.Text>
                            <span>Kỷ luật: </span> <b>{t(reportData?.class_report_status?.discipline)}</b>
                            <br />
                            <span>
                                <CommentOutlined /> &nbsp;
                                {t(reportData?.class_report_status?.discipline_content || "Không có nhận xét")}
                            </span>
                        </Typography.Text>
                    </div>
                    <div className="flex__start__center gap">
                        <img alt="content" src={rp_study} />
                        <Typography.Text>
                            <span>Học tập: </span> <b>{t(reportData?.class_report_status?.study)}</b>
                            <br />
                            <span>
                                <CommentOutlined /> &nbsp;
                                {t(reportData?.class_report_status?.study_content || "Không có nhận xét")}
                            </span>
                        </Typography.Text>
                    </div>
                </div>
            </Col>
            <Col span={24}>
                <div className="report-section card --padding --no-border report-homework">
                    <Typography.Title level={5}>Bài tập về nhà</Typography.Title>
                    <Typography.Text>
                        {reportData?.class_homework?.content?.split("\n").map((item, index) => (
                            <span key={index}>
                                {item}
                                {index < reportData.class_homework.content?.split("\n").length - 1 && <br />}
                            </span>
                        ))}
                    </Typography.Text>
                </div>
            </Col>
            <Col span={12}>
                <div className="report-section card --padding report-commend">
                    <Typography.Title level={5}>Tuyên dương</Typography.Title>
                    <div className="flex__between__center gap">
                        <Typography.Text>
                            {reportData?.commend?.split("\n").map((item, index) => (
                                <span key={index}>
                                    {item}
                                    {index < reportData.commend.split("\n").length - 1 && <br />}
                                </span>
                            ))}
                        </Typography.Text>
                        <img alt="content" src={rp_commend} />
                    </div>
                </div>
            </Col>
            <Col span={12}>
                <div className="report-section card --padding report-criticize">
                    <Typography.Title level={5}>Nhắc nhở</Typography.Title>
                    <div className="flex__between__center gap">
                        <Typography.Text>
                            {reportData?.criticize?.split("\n").map((item, index) => (
                                <span key={index}>
                                    {item}
                                    {index < reportData.criticize?.split("\n").length - 1 && <br />}
                                </span>
                            ))}
                        </Typography.Text>
                        <img alt="content" src={rp_remind} />
                    </div>
                </div>
            </Col>
            {(me?.role !== "ROLE_USER" || !reportData?.is_confirmed) && (
                <Col span={24}>
                    <div className="report-section card --padding report-comment">
                        <Typography.Title level={5}>Nhận xét của quản lý</Typography.Title>
                        <Input.TextArea
                            defaultValue={reportData?.admin_comment || "Chưa có nhận xét"}
                            style={{ padding: 0 }}
                            disabled={me?.role !== "ROLE_ADMIN" || reportData?.is_confirmed}
                            bordered={false}
                            rows={2}
                            onChange={(e) => onComment && onComment(e.target.value)}
                        />
                    </div>
                </Col>
            )}
        </Row>
    );
}

export default Report;
