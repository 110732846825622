import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { handleFetchApi } from "../../../configs/apiRequest";
import { StoreContext } from "../../../stores";
import Loading from "../../../components/Loading";
import { useQuery } from "@tanstack/react-query";
import { Col, Divider, Image, Input, Row, Select, Typography } from "antd";
import CustomSearchIcon from "../../../components/Icons/CustomSearchIcon";
import { debounce, imageFallback } from "../../../utils";
import NoData from "../../../components/NoData";
import { useNavigate } from "react-router-dom";
import BookItem from "./BookItem";
import BookList from "./BookList";

function SelectBook({ selectedBook, selectedItems, setSelectedBook }) {
    const { t } = useTranslation();
    const { me } = useContext(StoreContext);
    let navigate = useNavigate();
    const [filteredBooks, setFilteredBooks] = useState();

    const {
        isError,
        data: books,
        isLoading,
    } = useQuery(
        ["all_book"],
        async () => {
            const res = await handleFetchApi("get", `admin/book-codes/look-up/access-code/users/${me?.id}?size=99&page=0`);

            return res?.data?.content;
        },
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    const handleFilterBooks = useCallback((keyword) => {
        if (!keyword) {
            setFilteredBooks(undefined);
            return;
        }
        const results = books?.filter((b) => b.title?.toLowerCase().includes(keyword));
        setFilteredBooks(results?.length === books?.length ? [] : results || []);
    }, [books]);

    return (
        <div>
            <div className="flex__between__center gap">
                <Input
                    onChange={debounce(e => handleFilterBooks(e.target.value?.toLowerCase()), 500)}
                    prefix={<CustomSearchIcon />}
                    placeholder={t('search_by_book_name')}
                    size="large"
                />
                <div className="flex__center__center gap">
                    <Select
                        defaultValue="all"
                        options={[{ value: "all", label: t('all_grades') }]}
                        size="large"
                    />
                    <Select
                        defaultValue="all"
                        options={[{ value: "all", label: t('all_publishers') }]}
                        size="large"
                    />
                </div>
            </div>
            <br />
            <Row>
                <Col span={16}>
                    <Typography.Title level={4}>
                        {filteredBooks ? t('search_results', { count: filteredBooks.length }) : t('suggestions')}
                    </Typography.Title>
                    <BookList setSelectedBook={setSelectedBook} items={filteredBooks || books?.slice(0, 5)} />
                </Col>
                {selectedBook && (
                    <Col span={8}>
                        <Row gutter={12} className="selected_book_preview">
                            <Typography.Title level={4}>{selectedBook?.title}</Typography.Title>
                            <Col span={12}>
                                <Row justify={"start"} className="flex__start__start gap">
                                    <Col span={24} className="flex__start__start flex__column">
                                        <Image width={"100%"} preview={false} src={selectedBook?.cover_url} alt="selectedBook_image" fallback={imageFallback} />
                                        {/* <b style={{ float: "right" }}>{selectedBook?.title}</b> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <p>{t('author')}: {selectedBook?.author || t('updating')}</p>
                                <p>{t('description')}: {selectedBook?.description ?? t('updating')}</p>
                            </Col>
                            {/* <Divider />
                            <Col span={24}>
                                <p>Số lượng đề: 5</p>
                                <p>Số lượng bài: 25</p>
                                <p>Số bài đã chọn: {selectedItems?.length}</p>
                            </Col> */}
                        </Row>
                    </Col>
                )}
            </Row>
            {isLoading && <Loading />}
        </div>
    );
}

export default SelectBook;
