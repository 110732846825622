import React, { useMemo, useCallback } from "react";
import { cacheQueryConfig, handleFetchApi } from "../../../configs/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { Button, Col, Divider, message, Modal, Row, Typography } from "antd";
import moment from "moment";
import Loading from "../../../components/Loading";
import good from "../../../assets/images/good.png";
import { useNavigate } from "react-router-dom";
import { confirmStartAssignment, startAssignmentNoGrade } from "../../../utils/exercises";
import { isEmpty } from "../../../utils";
import NoData from "../../../components/NoData";
import { useTranslation } from "react-i18next";

const type2Class = {
    TEST: "test",
    NORMAL: "homework",
    NO_GRADE: "read",
};

const UnSubmitted = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { data: assignments, isFetching } = useQuery(
        [`student_assignment_unsubmited`],
        async () => {
            const res = await handleFetchApi("get", `assignment/student-by-submitted?isSubmitted=false`);
            const groupedData = res?.data.reduce((acc, assignment) => {
                const status = assignment.status || "NOT_YET_STARTED";
                if (!acc[status]) {
                    acc[status] = [];
                }
                acc[status].push(assignment);
                return acc;
            }, {});
            return groupedData;
        },
        cacheQueryConfig({
            time: 3,
        })
    );

    const renderAssignmentCard = useCallback((assignment) => {
        const { gradingId, status } = assignment;
        const deadline = moment(assignment?.deadlineAt);
        const now = moment();
        const isOverdue = now.isAfter(deadline);
        const duration = moment.duration(deadline.diff(now));
        const days = Math.max(0, Math.floor(duration.asDays()));
        const hours = Math.max(0, duration.hours());
        const minutes = Math.max(0, duration.minutes());
        const seconds = Math.max(0, duration.seconds());
        return (
            <Col sm={24} md={12} xl={8} key={assignment?.id}>
                <div className={`card --hoverable flex__start__start flex__column card_assignment __${assignment?.type} --stretch`}>
                    <Typography.Title level={4}>{assignment?.name}</Typography.Title>
                    <Typography.Text>Hạn nộp: {assignment?.deadlineAt ? deadline.format("DD/MM/YYYY HH:mm") : "Không có"}</Typography.Text>
                    {assignment?.deadlineAt &&
                        (isOverdue ? (
                            <Typography.Text type="danger" strong>
                                QUÁ HẠN
                            </Typography.Text>
                        ) : (
                            <Typography.Text>
                                {`Còn: ${days > 0 ? `${days} ngày ` : ""}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
                                    .toString()
                                    .padStart(2, "0")}`}
                            </Typography.Text>
                        ))}
                    <Button
                        className="btn-white --md --border-dark"
                        onClick={() => {
                            if (assignment?.type === "NOGRADE") return startAssignmentNoGrade({ assignment: assignment, navigate: navigate });
                            if (gradingId) {
                                navigate(`/assignment/${assignment?.id}/grading/${gradingId}`);
                            } else confirmStartAssignment({ assignment, navigate });
                        }}
                    >
                        {status === "DOING" ? "LÀM TIẾP" : "BẮT ĐẦU"}
                    </Button>
                    <div className="__status">{assignment.status === "DOING" ? "Đang làm" : "Chưa làm"}</div>
                </div>
            </Col>
        );
    }, []);

    const renderAssignmentSection = useCallback(
        (status, title) => {
            const assignmentsForStatus = assignments?.[status] || [];
            const assignmentCards = assignmentsForStatus?.map(renderAssignmentCard);

            return assignmentCards.length > 0 ? (
                <section key={status}>
                    <Typography.Title level={4}>{title}</Typography.Title>
                    <Row gutter={[16, 16]}>{assignmentCards}</Row>
                </section>
            ) : null;
        },
        [assignments, renderAssignmentCard]
    );

    return (
        <div className="loading_container">
            <br />
            {renderAssignmentSection("DOING", "Nhiệm vụ đang thực hiện")}
            <br />
            {renderAssignmentSection("NOT_YET_STARTED", "Nhiệm vụ đang chờ")}
            {(!assignments || isEmpty(assignments)) && <NoData imageWidth={100} img={good} text={t("noAssignmentsInWaiting")} />}
            {isFetching && <Loading />}
        </div>
    );
};

export default React.memo(UnSubmitted);
