import React, { memo } from "react";
import { Col, Image } from "antd";
import { imageFallback } from "../../../utils";

const BookItem = memo(({ book, selectedBook, onSelect }) => (
    <Col
        onClick={() => onSelect(book)}
        span={4}
        className={`flex__start__start flex__column book_item ${selectedBook?.book_id === book?.book_id ? "--selected" : "--unselected"}`}
    >
        <Image width="100%" preview={false} src={book?.cover_url} alt="book_image" fallback={imageFallback} />
        <b>{book?.title}</b>
    </Col>
));

export default BookItem;
