import { Button, Modal, Typography } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const COUNT_DEFAULT = 60;

function ModalConfirmSuccess({ email, reSendEmail }) {
    const { t } = useTranslation();
    const [countDown, setCountDown] = useState(COUNT_DEFAULT);
    const navigate = useNavigate();

    useEffect(() => {
        let interval;
        if (countDown > 0) {
            interval = setInterval(() => {
                setCountDown(prev => prev - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [countDown]);

    const handleResend = useCallback(() => {
        setCountDown(COUNT_DEFAULT);
        reSendEmail();
    }, [reSendEmail]);

    return (
        <Modal 
            className="email_send_success" 
            width={"100dvw"} 
            open={true} 
            title={null} 
            footer={null} 
            onCancel={() => navigate('/login')}
        >
            <Typography.Title level={3}>{t('password_reset_link_sent')}</Typography.Title>
            <Typography.Text type="secondary">
                {t('check_email_instructions', { email: email })}
            </Typography.Text>
            <Button
                disabled={countDown > 0}
                onClick={handleResend}
            >
                {t('not_received_resend')}
            </Button>
            {countDown > 0 && (
                <Typography.Text type="secondary">
                    {t('resend_after', { seconds: countDown })}
                </Typography.Text>
            )}
        </Modal>
    );
}

export default ModalConfirmSuccess;
