import React, { Fragment, Suspense, lazy } from "react";
import { Breadcrumb, Col, Row, Tabs } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../components/Loading";
import  { handleFetchApi } from "../../configs/apiRequest";
import { t } from "i18next";
import ErrorPage from "../Error";

const HeaderDashboard = lazy(() => import("../../components/HeaderDashboard"));
const TeachersTab = lazy(() => import("./components/TeacherTab"));

function ClassesCourse(props) {
    let { course_id } = useParams();

    let {
        data: course,
        isError,
        isLoading,
        refetch,
    } = useQuery(
        [course_id],
        async () => {
            const res = await handleFetchApi("get", `course/${course_id}`);
            return res?.data;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const courseTabs = course?.users.map((teacher) => ({
        label: teacher.name,
        key: teacher.id,
        children: (
            <Suspense fallback={<Loading />}>
                <TeachersTab refetch={refetch} course_id={course_id} teacher_id={teacher.id} />
            </Suspense>
        ),
    }));

    if (isLoading) return <Loading />;
    if (isError) return <ErrorPage />;

    return (
        <>
            <Suspense fallback={"..."}>
                <HeaderDashboard />
            </Suspense>
            <section id="p_manager" className="flex__start__center flex__column">
                <Row className="container" justify="center" align="middle">
                    <Col span={24}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">
                                    <HomeOutlined />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/manage">
                                    {t("manage")} {t("courses")}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{course?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <section className="container">
                    <Tabs items={courseTabs} />
                </section>
            </section>
        </>
    );
}

export default ClassesCourse;
