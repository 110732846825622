import CustomBookIcon from "../components/Icons/CustomBookIcon";
import CustomSettingIcon from "../components/Icons/CustomSettingIcon";
import CustomListExerciseIcon from "../components/Icons/CustomListExerciseIcon";
import CustomReportScoreIcon from "../components/Icons/CustomReportScoreIcon";
import CustomHomeIcon from "../components/Icons/CustomHomeIcon";
import CustomClassIcon from "../components/Icons/CustomClassIcon";

const HomePage = {
    key: "dashboard",
    label: "dashboard",
    link: "/",
    icon: <CustomHomeIcon />,
};
const MyLibrary = {
    key: "mylib",
    label: "mylib",
    link: "/my_library",
    icon: <CustomBookIcon />,
};

const Setting = {
    key: "setting",
    label: "setting",
    link: "/setting",
    icon: <CustomSettingIcon />,
};

const Exercises = {
    key: "exercises",
    label: "exercises",
    link: "/exercises",
    icon: <CustomListExerciseIcon />,
};

const StudentReport = {
    key: "report",
    label: "report",
    link: "/report",
    icon: <CustomReportScoreIcon />,
};

const StudentProfile = {
    key: "student_profile",
    label: "student_profile",
    link: "/student/profile",
    icon: <CustomClassIcon />,
};

const AdminReport = {
    key: "report",
    label: "report",
    link: "/admin/report",
    icon: <CustomReportScoreIcon />,
};

const ManageSchool = {
    key: "school",
    label: "school",
    link: "/admin/schools",
    icon: <CustomHomeIcon />,
};

const ManageCourse = {
    key: "course",
    label: "courses",
    link: "/admin/courses",
    icon: <CustomClassIcon />,
};

export const MenuByRole = {
    ROLE_TEACHER: [MyLibrary],
    ROLE_USER: [HomePage, MyLibrary, Exercises, StudentReport],
    ROLE_ADMIN: [ManageSchool, ManageCourse, AdminReport],
};
