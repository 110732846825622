import React, { useState } from "react";
import { Table, Spin, Layout, Button, Input, Drawer, Row, Col, Typography } from "antd";
import { useQuery } from "@tanstack/react-query"; // Updated import
import { handleFetchApi } from "../../configs/apiRequest";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import SchoolDetail from "./components/SchoolDetail";
import CourseDetail from "./components/CourseDetail";

const ManageCourse = () => {
    const { t } = useTranslation();
    const { data, isLoading, error } = useQuery(["schools"], () => handleFetchApi("GET", "/course"));
    const [selectedCourse, setSelectedCourse] = useState();

    if (error) return <div>Error fetching schools</div>;

    return (
        <Layout.Content>
            <div className="page">
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <div className="flex__between__center gap">
                            <Input size="large" placeholder={"Tìm kiếm"} />
                            <Button size="large" type="primary" icon={<PlusOutlined />}>
                                Thêm
                            </Button>
                        </div>
                        <br />
                        {data?.data?.map((course) => (
                            <div
                                className={`card --padding --hoverable --mb ${selectedCourse?.id === course?.id && "--active"}`}
                                onClick={() => setSelectedCourse(course)}
                            >
                                <Typography.Text className="flex__between__center">
                                    {course.name}
                                    <small style={{ color: "#F3A000" }}>{course.year}</small>
                                </Typography.Text>
                                <Typography.Text type="secondary">Số lớp: {course?.users?.length} lớp</Typography.Text>
                            </div>
                        ))}
                        {isLoading && <Loading />}
                    </Col>
                    {selectedCourse && <CourseDetail course={selectedCourse} />}
                </Row>
            </div>
        </Layout.Content>
    );
};

export default ManageCourse;
