import { Breadcrumb, Button, Divider, Empty, Layout, List, Modal, Space, Typography } from "antd";
import React, { Suspense, lazy, useContext, useState } from "react";
import { EditOutlined, HomeOutlined, PlusOutlined, TeamOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import DelayedComponent from "../../components/DelayedComponent";
import { useQuery } from "@tanstack/react-query";
import { StoreContext } from "../../stores";
import { handleFetchApi } from "../../configs/apiRequest";
import { t } from "i18next";
import Loading from "../../components/Loading";
import { UserEnums, isAdmin, isTeacher } from "../../configs/enums";
import ErrorPage from "../Error";

const { Content } = Layout;
const NoData = lazy(() => import("../../components/NoData"));
const CreateCourse = lazy(() => import("./components/CreateCourse"));

function ManageCourses(props) {
    let navigate = useNavigate();
    const { me } = useContext(StoreContext);
    const [modal, setModal] = useState({
        visible: false,
        content: null,
        step: 0,
        type: "CREATE",
    });
    let {
        data: courses,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useQuery(
        ["courses"],
        async () => {
            let path = "course";
            if (isTeacher(me)) {
                path += `/teacher/${me.id}`;
            }
            const courseData = await handleFetchApi("get", path);
            return courseData?.data?.sort((a, b) => b.created_at - a.created_at);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const handleSelectCourse = (course) => {
        if (isTeacher(me)) return;
        if (!course.users?.length) {
            setModal((prevState) => ({
                ...prevState,
                visible: true,
                content: course,
                step: 1,
            }));
            return;
        }
        if (isAdmin(me)) navigate(`/manage/course/${course.id}`);
    };

    const toggleModal = (visible, step) => {
        setModal((prevState) => ({
            ...prevState,
            visible: visible,
            step: step || 0,
        }));
    };

    const handleActiveBook = (e, course) => {
        e.stopPropagation();
        setModal((prevState) => ({
            ...prevState,
            visible: true,
            content: course,
            step: 2,
        }));
    };

    if (isError) return <ErrorPage />;

    return (
        <Content>
            {isLoading && <Loading />}
            <Suspense>
                <section id="p_manager" className="page flex__start__center flex__column">
                    <div className="container page__body flex__start__stretch flex__wrap gap ">
                        {courses?.length ? (
                            courses.map((course, index) => (
                                <DelayedComponent key={course.course_teacher_id} timeDelay={(index + 1) * 0.2 * 300}>
                                    <div key={course.course_teacher_id} className="container__items delay-render-list__items">
                                        <div onClick={() => handleSelectCourse(course)} className="card course">
                                            <h2 className="card__body__title flex__between__center">
                                                {isAdmin(me) ? (
                                                    <>
                                                        {course.courseName || course.name}
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setModal((prevState) => ({
                                                                    ...prevState,
                                                                    visible: true,
                                                                    content: course,
                                                                    step: 0,
                                                                    type: "UPDATE",
                                                                }));
                                                            }}
                                                            type="text"
                                                            icon={<EditOutlined />}
                                                        />
                                                    </>
                                                ) : (
                                                    course.course.name
                                                )}
                                            </h2>
                                            {isAdmin(me) ? (
                                                <>
                                                    <Space className="flex__between__center card__body">
                                                        <Typography.Text type="danger">
                                                            <TeamOutlined />{" "}
                                                            {course.users?.length ? `${course.users?.length} Giáo viên` : "Chưa thêm giáo viên"}
                                                        </Typography.Text>
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setModal((prevState) => ({
                                                                    ...prevState,
                                                                    visible: true,
                                                                    content: course,
                                                                    type: "ADD_TEACHER",
                                                                }));
                                                            }}
                                                            type="text"
                                                            icon={<PlusOutlined />}
                                                        />
                                                    </Space>
                                                    <List
                                                        header={null}
                                                        footer={null}
                                                        // bordered
                                                        dataSource={course.users.slice(course.users?.length - 5)}
                                                        renderItem={(item, index) => <List.Item>{item.name}</List.Item>}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Divider style={{ margin: "0.5rem 0" }} />
                                                    {course.classes?.length ? (
                                                        <>
                                                            <Typography.Text>
                                                                Danh sách lớp
                                                                {me?.role === UserEnums.ADMIN && (
                                                                    <Button
                                                                        onClick={(e) => handleActiveBook(e, course)}
                                                                        className="btn_extra"
                                                                        type="text"
                                                                        icon={<PlusOutlined />}
                                                                    />
                                                                )}
                                                            </Typography.Text>
                                                            <List
                                                                header={null}
                                                                footer={null}
                                                                // bordered
                                                                dataSource={course.classes}
                                                                renderItem={(item, index) => (
                                                                    <List.Item
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                        }}
                                                                        key={item.id}
                                                                    >
                                                                        <Link to={`/manage/course/${course.id || course?.course.id}/class/${item.id}`}>
                                                                            {item.name} - {item.school?.name}
                                                                        </Link>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Empty description="Chưa có lớp học nào" />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </DelayedComponent>
                            ))
                        ) : (
                            <NoData />
                        )}
                    </div>
                </section>

                <Modal
                    closeIcon={() => <></>}
                    header={null}
                    footer={null}
                    open={modal.visible}
                    onOk={() => toggleModal(false, 0)}
                    onCancel={() => toggleModal(false, 0)}
                >
                    <CreateCourse type={modal?.type} selectedCourse={modal.content} setModal={setModal} refetch={refetch} />
                </Modal>
            </Suspense>
        </Content>
    );
}

export default ManageCourses;
    