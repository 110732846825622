import { Avatar, Button, Dropdown, Input, Modal, Space, Typography, message, Layout, Popover, Badge } from "antd";
import React, { lazy, useContext, useState, useEffect } from "react";
import { logout } from "../../utils";
import { StoreContext } from "../../stores";
import {
    ArrowLeftOutlined,
    CloseOutlined,
    DownOutlined,
    ExclamationCircleTwoTone,
    HomeFilled,
    KeyOutlined,
    LeftOutlined,
    LogoutOutlined,
    MenuOutlined,
    SolutionOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { isStudent } from "../../configs/enums";
import ButtonNotification from "./ButtonNotification";
import avatarImg from "../../assets/images/avatar.png";
import PopoverProfile from "./PopoverProfile";
import { useNavigate } from "react-router-dom";
import CustomLeftIcon from "../Icons/CustomLeftIcon";
import CustomDangerIcon from "../Icons/CustomDangerIcon";

const ButtonTest = lazy(() => import("./ButtonTest"));
const { Header } = Layout;

const tabletMode = window.innerWidth >= 1024 ? false : true;

function HeaderDashboard(props) {
    const navigate = useNavigate();

    const { t } = useTranslation(["translation"]);
    const [hiddenMenu, setHiddenMenu] = useState(false);
    const [newName, setNewName] = useState();
    const { me, breadcrumb, setBreadcrumb } = useContext(StoreContext);
    let styles = {
        width: "100%",
        textAlign: "left",
    };

    const updateProfile = useMutation({
        mutationFn: () => {
            const data = {
                name: newName,
            };
            if (!newName || newName === me?.username) return message.error("Tên không được trùng với tên đăng nhập!");
            return handleFetchApi("put", "profile", data);
        },
        onSuccess: (response) => {
            if (response?.status !== "S0000") {
                return;
            }
            message.success("Cập nhật thành công. Chúc bạn học tập vui vẻ!");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            return;
        },
    });

    useEffect(() => {
        const alertUpdateEmail = () => {
            Modal.confirm({
                title: "Bạn chưa cập nhật email",
                content: "Cập nhật email giúp bạn xác thực tài khoản của mình, và lấy lại mật khẩu khi lỡ quên mật khẩu. Hãy cập nhật email nhé!",
                centered: true,
                icon: <CustomDangerIcon />,
                okText: "Cập nhật ngay",
                cancelText: t("cancel"),
                onCancel: () => localStorage.setItem("email_verified", true),
                onOk: () => localStorage.setItem("email_verified", true),
            });
        };

        if (localStorage.getItem("email_verified") === "false") {
            alertUpdateEmail();
        }
    }, []);

    return (
        <Header className="cb_header flex__between__center">
            <Typography.Title className="flex__start__center gap" level={3}>
                {breadcrumb?.link && (
                    <Button
                        onClick={() => {
                            setBreadcrumb({ label: breadcrumb?.oldLabel });
                            navigate(breadcrumb.link);
                        }}
                        icon={<CustomLeftIcon />}
                        type="text"
                        size="small"
                    />
                )}
                {t(breadcrumb?.label)}
            </Typography.Title>
            {/* <Button
                onClick={() => setHiddenMenu(!hiddenMenu)}
                className="cb_header__icon"
                type="link"
                icon={hiddenMenu ? <CloseOutlined /> : <MenuOutlined />}
            /> */}
            <div className={`flex__center__center gap-sm cb_header__menu ${hiddenMenu ? "--active" : "--hidden"}`}>
                <>
                    <ButtonNotification />
                    <Popover content={<PopoverProfile />} trigger={["click"]} placement={"bottomLeft"}>
                        <Button className="btn-white flex__center__center cb_header__menu__dropdown" size="large">
                            <Badge dot={!me?.email_verified}>
                                <Avatar src={avatarImg} />
                            </Badge>&nbsp;
                            <Typography.Text> {me?.name}</Typography.Text>
                            <DownOutlined className="cb_header__menu__dropdown__icon" />
                        </Button>
                    </Popover>
                </>
            </div>
            {me?.name === me?.username && isStudent(me) && (
                <Modal width={330} open={true} title="Bạn chưa cập nhật thông tin?" onOk={() => {}} footer={null}>
                    <div className="flex__center__center flex__column gap">
                        <Typography.Text type="danger">Để sử dụng dịch vụ được tốt hơn, vui lòng cho chúng tôi biết tên của bạn nhé!</Typography.Text>
                        <Input onChange={(e) => setNewName(e.target.value)} placeholder="Họ và Tên" />
                        <Button type="primary" onClick={updateProfile.mutate}>
                            Xác nhận
                        </Button>
                    </div>
                </Modal>
            )}
        </Header>
    );
}

export default HeaderDashboard;
