import React, { useEffect, useState } from "react";
import NoData from "../../components/NoData";
import { Button, Typography } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import error500 from "../../assets/images/error500.png";

const DEFAULT_MESSAGE = "Server hiện không phản hồi hoặc đang bảo trì!";
const styles = {
    height: "100vh",
};

function ErrorPage({ message = DEFAULT_MESSAGE }) {
    const [countDown, setCountDown] = useState(90);

    const reloadPage = () => {
        if ("caches" in window) {
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
        }
        if (window.location.pathname === "/error-page") {
            window.location = "/";
        } else window.location.reload();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDown - 1);
        }, 1000);
        if (!countDown) reloadPage();

        return () => {
            clearInterval(interval);
        };
    }, [countDown]);

    return (
        <div style={styles} className="flex__center__center">
            <NoData
                img={error500}
                imageWidth={500}
                text={
                    <>
                        <Typography.Title level={4} type="danger">
                            {message}
                        </Typography.Title>
                        <Typography.Text>
                            Hãy quay lại sau vài phút hoặc thử <b>Tải Lại Trang</b> xem sao?
                        </Typography.Text>

                        <br />
                        <br />
                        <Button size="large" onClick={reloadPage} icon={<RedoOutlined />} type="primary" danger>
                            Tải Lại Trang
                        </Button>
                        <br />
                        <Typography.Text type="secondary" strong>
                            Tự động kết nối lại sau : {countDown} giây.
                        </Typography.Text>
                        <br />
                    </>
                }
            />
        </div>
    );
}

export default ErrorPage;
