import { Layout } from "antd";
import React, { Suspense, lazy, memo } from "react";

const DraffExercise = lazy(() => import("./DraffExercise"));
const ButtonSubmit = lazy(() => import("./ButtonSubmit"));

const { Sider } = Layout;

function Controls({ setActiveKey, activeKey, grading }) {
    return (
        <Suspense fallback={"loading..."}>
            <Sider width="clamp(200px, 20dvw, 400px)" className="cb_exercise_mode__controls flex__between__start flex__column">
                <DraffExercise grading={grading} />
                {/* <ButtonSubmit /> */}
            </Sider>
        </Suspense>
    );
}

export default memo(Controls);
