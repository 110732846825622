import { Breadcrumb, Col, Row, Tabs } from "antd";
import React, { Suspense, lazy } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import Loading from "../../components/Loading";
import ErrorPage from "../Error";

const Statistic = lazy(() => import("./components/Statistic"));
const ExercisesTab = lazy(() => import("./components/ExercisesTab"));
const StudentsTab = lazy(() => import("./components/StudentsTab"));
const HeaderDashboard = lazy(() => import("../../components/HeaderDashboard"));

function ManageClasses(props) {
    let { class_id } = useParams();
    let {
        data: myClass,
        isLoading,
        isError,
    } = useQuery(
        [`my_${class_id}`],
        async () => {
            const res = await handleFetchApi("get", `class/${class_id}`);
            return res?.data;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const CLASS_TABS = [
        {
            label: "Nhiệm vụ",
            key: "classes",
            children: (
                <Suspense fallback={<Loading />}>
                    <ExercisesTab />
                </Suspense>
            ),
        },
        {
            label: "Học sinh",
            key: "students",
            children: (
                <Suspense fallback={<Loading />}>
                    <StudentsTab />
                </Suspense>
            ),
        },
        {
            label: "Thống Kê",
            key: "statistic",
            children: (
                <Suspense fallback={<Loading />}>
                    <Statistic />
                </Suspense>
            ),
        },
    ];

    if (isLoading) return <Loading />;
    if (isError) return <ErrorPage />;

    return (
        <Suspense fallback={<Loading />}>
            <HeaderDashboard />
            <section id="p_manager" className="page flex__start__center flex__column">
                <Row className="page__header container" justify="center" align="middle">
                    <Col span={24}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">
                                    <HomeOutlined />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/manage">
                                    {t("manage")} {t("courses")}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <span>{myClass?.name}</span>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        {/* <Button className="btn_extra" type="link">
                        Quản lý Khóa học
                    </Button> */}
                    </Col>
                </Row>
                <section className="container">
                    <Tabs items={CLASS_TABS} />
                </section>
            </section>
        </Suspense>
    );
}

export default ManageClasses;
