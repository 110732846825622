import { handleFetchApi } from "../../../configs/apiRequest";

const renderParams = (ids, key) => {
    if (!ids) return "";
    let params = "";
    ids.forEach((element) => {
        params += `${key}=${element}&`;
    });
    return params.replace(/&$/, "");
};

const fetchExerciseChildren = async ({ itemIds, setExerciseChildren, exerciseChildren }) => {
    if (!itemIds) return;
    let path = `items/search?${renderParams(itemIds, "ids")}`;
    const res = await handleFetchApi("GET", path);
    res?.data?.forEach((element) => {
        setExerciseChildren(new Map(exerciseChildren.set(element.id, element)));
    });
};

export const fetchExercises = async ({ exercisesIds, setExerciseChildren, exerciseChildren }) => {
    let path = `items/search?${renderParams(exercisesIds, "ids")}`;
    const response = await handleFetchApi("GET", path);
    const exercises = response.data?.sort((a, b) => a.created_at - b.created_at);
    await Promise.all(exercises?.map(async (ex) => {
        // exercise text base don't includes children
        if (!ex?.is_text_based) {
            setExerciseChildren(new Map(exerciseChildren.set(ex.id, ex)));
            await fetchExerciseChildren({ itemIds: ex.content?.children, setExerciseChildren, exerciseChildren });
        }
    }));
    return exercises;
};

export const getAssignment = async ({ assignmentId }) => {
    const response = await handleFetchApi("GET", `assignment/${assignmentId}`);
    return response?.data;
};

export const getAssignmentGrading = async (assignmentId, userId) => {
    const response = await handleFetchApi("get", `assignment/${assignmentId}/grading/${userId}`);
    let assignmentGrading = response?.data?.sort((a, b) => b.created_at - a.created_at) || [];
    return assignmentGrading?.length ? assignmentGrading[0] : assignmentGrading;
};
