import React, { useContext, useState, useCallback, useEffect } from "react";
import { StoreContext } from "../../../stores";
import { Segmented } from "antd";
import TeacherAssignment from "../components/TeacherAssignment";
import Loading from "../../../components/Loading";
import ListStudent from "../components/ListStudent";
import TeacherReport from "../components/TeacherReport";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

function TeacherDashboard() {
    
    const { courseId, classId } = useParams();
    const { selectedClass, setSelectedClass } = useContext(StoreContext);
    const [viewMode, setViewMode] = useState("assignment");
    const { t } = useTranslation();

    useEffect(() => {
        if (courseId && classId) setSelectedClass({ id: classId, courseId });
    }, [courseId, classId, setSelectedClass]);

    const renderViewMode = useCallback(() => {
        switch (viewMode) {
            case "assignment":
                return <TeacherAssignment />;
            case "list_student":
                return <ListStudent />;
            case "report":
                return <TeacherReport />;
            default:
                return null;
        }
    }, [viewMode]);

    const handleViewModeChange = useCallback((value) => setViewMode(value), []);

    if (!selectedClass) return <Loading />;
    
    return (
        <>
            <Segmented
                onChange={handleViewModeChange}
                size="large"
                options={[
                    {
                        label: t("tests_assignments"),
                        value: "assignment",
                    },
                    {
                        label: t("student_list"),
                        value: "list_student",
                    },
                    {
                        label: t("comments"),
                        value: "report",
                    },
                ]}
            />
            {renderViewMode()}
        </>
    );
}

export default React.memo(TeacherDashboard);
