import axios from "axios";
import Cookies from "js-cookie";
import { returnLogin } from "../utils";
import { Modal, Typography } from "antd";
import CustomDangerIcon from "../components/Icons/CustomDangerIcon";

const isDevMode = window.location.href?.includes("localhost") || window.location.href.includes("staging");

const api = axios.create({
    baseURL: `https://${isDevMode ? "staging." : ""}api.cloudbook.vn/api/v1`,
});

const AUTHENTICATION_FAIL = ["UR0015", "UR0014", "UR0013", "UR0001"];

api.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get("access_token");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.data?.code === "UR0015") {
            try {
                const refreshToken = Cookies.get("refresh_token");
                const { data } = await axios.post("/auth/token/refresh", { refresh_token: refreshToken });

                Cookies.set("access_token", data.access_token);
                Cookies.set("refresh_token", data.refresh_token);

                originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.error("Refresh token error", refreshError);
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export const cacheQueryConfig = ({ time = 3 }) => ({
    staleTime: time * 60000,
    cacheTime: time * 60000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

export const handleFetchApi = async (method, endpoint, data = null) => {
    try {
        const response = await api({ method, url: endpoint, data });
        if (AUTHENTICATION_FAIL.includes(response?.data?.status)) {
            returnLogin();
            throw new Error("Authentication failed");
        }
        return response.data;
    } catch (error) {
        console.error("API call error", error);
        // Modal.error({
        //     title: "Lỗi không mong muốn",
        //     content: <Typography.Text type="danger" >{error?.response?.data?.message}</Typography.Text>,
        //     centered: true,
        //     icon: <CustomDangerIcon />,
        // });
        if (error.code === "ERR_NETWORK") {
            window.location = "/error-page";
        } else if (error.code === "ERR_BAD_REQUEST" && !AUTHENTICATION_FAIL.includes(error.response?.data?.code)) {
            // returnLogin()

            throw error; // Re-throw the error for proper error handling in the calling function
        }
    }
};
