import { useEffect, useState } from "react";

function DelayedComponent(props) {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setDisplay(true);
    }, props.timeDelay);
    return () => {
      clearTimeout(timeout);
    };
  }, [props.timeDelay]);

  return display ? props.children : null;
}

export default DelayedComponent;
