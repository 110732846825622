import React, { Suspense, useContext } from "react";
import Loading from "../../../components/Loading";
import { Button, Col, Form, Input, message, Modal, notification, Row, Typography } from "antd";
import { StoreContext } from "../../../stores";
import { useTranslation } from "react-i18next";
import CustomPhoneIcon from "../../../components/Icons/CustomPhoneIcon";
import HeaderLogin from "../../../components/HeaderLogin";
import mainblog from "../../../assets/images/bee_login.png";
import CustomLockIcon from "../../../components/Icons/CustomLockIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { AAUTH } from "../../../apis/auth";
import CustomSuccessIcon from "../../../components/Icons/CustomSuccessIcon";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";

const SetNewPassword = () => {
    const { loading } = useContext(StoreContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const token = searchParams.get("token");
    const username = searchParams.get("username");

    const isValidPassword = (password) => {
        const minLength = 8;
        const uppercasePattern = /[A-Z]/;
        return password.length >= minLength && uppercasePattern.test(password);
    };

    const setNewPassword = useMutation({
        mutationFn: (values) => handleFetchApi("POST", AAUTH.setNewPassword, { ...values, token, username }),
        onSuccess: (response) => {
            if (response?.status !== 'S0000') {
                return Modal.error({
                    title: 'Không thành công!',
                    content: 'Liên kết này đã hết hạn, vui lòng thực hiện lại yêu cầu!',
                    centered: true,
                    icon: <CustomDangerIcon />,
                    onOk: () => navigate('/forgot_password')
                })
            }
            return Modal.success({
                title: 'Đổi mật khẩu thành công',
                content: 'Bạn đã đổi mật khẩu mới thành công, vui lòng đăng nhập lại nhé!',
                centered: true,
                icon: <CustomSuccessIcon />,
                onOk: () => navigate('/login'),
                okText: "Đăng nhập lại"
            })
            // notification.success({ message: t("password_change_success") });
            ;
        },
    });

    return (
        <div className="cb__login">
            <Suspense fallback={<Loading />}>
                <Row className="cb__login__content container" justify="space-evenly" align="stretch">
                    <HeaderLogin />
                    <Col xs={0} sm={8} md={8} lg={9} xl={10} className="flex__center__center flex__column">
                        <Typography.Title className="welcome_title" level={3}>
                            {t("set_new_password_for", { username })}
                        </Typography.Title>
                        <Form requiredMark={false} layout="vertical" name="set_new_password" onFinish={setNewPassword.mutate} autoComplete="off">
                            <Form.Item
                                label={t("new_password")}
                                name="new_password"
                                rules={[
                                    { required: true, message: t("pls_password") },
                                    {
                                        validator: (_, value) =>
                                            !value || isValidPassword(value)
                                                ? Promise.resolve()
                                                : Promise.reject(new Error(t("password_requirements"))),
                                    },
                                ]}
                            >
                                <Input.Password size="large" prefix={<CustomLockIcon />} placeholder={t("plh_new_password")} />
                            </Form.Item>
                            <Form.Item
                                label={t("confirm_new_password")}
                                name="confirm_password"
                                dependencies={['new_password']}
                                rules={[
                                    { required: true, message: t("confirm_password_required") },
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) =>
                                            !value || getFieldValue("new_password") === value
                                                ? Promise.resolve()
                                                : Promise.reject(new Error(t("password_mismatch"))),
                                    }),
                                ]}
                            >
                                <Input.Password size="large" prefix={<CustomLockIcon />} placeholder={t("plh_confirm_new_password")} />
                            </Form.Item>
                            <br />
                            <Button size="large" loading={loading} type="primary" htmlType="submit">
                                {t("confirm").toUpperCase()}
                            </Button>
                        </Form>
                        <Typography.Text type="secondary" className="hotline flex__center__center">
                            <CustomPhoneIcon /> {t("hotline")} <b>0782 469 899</b>
                        </Typography.Text>
                    </Col>
                    <Col xs={0} sm={8} md={8} lg={8} xl={10} style={{ margin: "auto 0" }} className="flex__center__center">
                        <img src={mainblog} alt="mainblog" />
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
};

export default SetNewPassword;
