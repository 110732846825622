import React, { useContext } from 'react';
import { Table, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { handleFetchApi } from '../../../configs/apiRequest';
import { StoreContext } from '../../../stores';
import Loading from '../../../components/Loading';

const { Title } = Typography;

const StudentDetailSummary = ({ studentId }) => {
    const { selectedClass } = useContext(StoreContext);

    const { data: summaryData, isLoading } = useQuery(
        ['studentActivitySummary', selectedClass?.courseId, studentId],
        () => handleFetchApi('GET', `/course/${selectedClass?.courseId}/student-result-summary/${studentId}`),
        {
            select: (response) => response.data?.content ?? [],
            onError: (error) => console.error('Error fetching student summary:', error),
        }
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'assignment_name',
            key: 'assignment_name',
        },
        {
            title: 'score',
            dataIndex: 'score',
            key: 'score',
        },
    ];

    return (
        <div>
            <Title level={4}>Student Activity Summary</Title>
            {isLoading ? (
                <Loading />
            ) : (
                <Table 
                    dataSource={summaryData} 
                    columns={columns} 
                    rowKey="activity"
                    pagination={false}
                />
            )}
        </div>
    );
};

export default StudentDetailSummary;
