import logo_text from "../assets/images/oeg_text.png";
import logo_without_text from "../assets/images/LOGO.png";
import oeg_login from "../assets/images/oeg_login.png";
import oeg_forgot_password from "../assets/images/oeg_forgot_password.png";

export const oegConfigs = {
    brandName: 'Oxbridge Group',
    logoWithText: logo_text,
    logoWithoutText: logo_without_text,
    login: oeg_login,
    forgotPassword: oeg_forgot_password,
}