import { QuestionOutlined, ZoomInOutlined, ZoomOutOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Typography, Popover, List } from "antd";
import React, { useContext } from "react";
import { ExerciseContext } from "../../../stores/exerciseStore";
import { t } from "i18next";
import { useTour } from "@reactour/tour";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import RatingByMark from "../../../components/RatingByMark";
import { fixedNumber } from "../../../utils/validation";
import CustomTestIcon from "../../../components/Icons/CustomTestIcon";
import ButtonExit from "../../../components/ButtonExit";
import CustomHistoryIcon from "../../../components/Icons/CustomHistoryIcon";
import moment from "moment";
import CustomClockIcon from "../../../components/Icons/CustomClockIcon";
import { useNavigate } from "react-router-dom";

function Header({ assignment, gradingId }) {
    const navigate = useNavigate();
    const { scale, setScale } = useContext(ExerciseContext);
    const { setIsOpen, setCurrentStep } = useTour();
    const { data: gradingHistory = [], isLoading } = useQuery(
        ["gradingHistory", assignment?.id],
        () => handleFetchApi("GET", `assignment/${assignment.id}/grading`),
        {
            enabled: !!assignment?.id,
            select: (response) => response.data || [],
        }
    );
    const headerTools = [
        {
            key: "zoomin",
            icon: <ZoomInOutlined />,
            title: t("zoomin"),
            actions: () => {
                scale !== 1 && setScale(scale + 1);
            },
        },
        {
            key: "zoomout",
            icon: <ZoomOutOutlined />,
            title: t("zoomout"),
            actions: () => {
                scale !== -5 && setScale(scale - 1);
            },
        },
        {
            key: "help",
            icon: <QuestionOutlined />,
            title: t("zoomout"),
            actions: () => {
                setCurrentStep(0);
                setIsOpen(true);
            },
        },
    ];

    const gradingMenu = (
        <div className="loading-container">
            <List
                dataSource={gradingHistory}
                loading={isLoading}
                renderItem={(item) => (
                    <div
                        onClick={() => navigate(`/assignment/${assignment?.id}/grading/${item?.id}`)}
                        key={item.id}
                        className={`card --small --padding --hoverable flex__between__center gap-sm ${item?.id === gradingId && '--active'}`}
                    >
                        <Typography.Text className="flex__center__center gap-sm">
                            <CustomClockIcon /> {moment(item.startedAt).format("DD/MM/YYYY HH:mm")}
                        </Typography.Text>
                        <Typography.Text type="danger">
                            <b>{fixedNumber(item?.evaluation?.mark ?? 0)}</b> <small>/10</small>
                        </Typography.Text>
                    </div>
                )}
            />
        </div>
    );

    return (
        <div className="cb_exercise_mode__header flex__between__center">
            <Typography.Title level={3} className="flex__start__center gap">
                <CustomTestIcon /> {assignment?.name}
            </Typography.Title>
            <div className="flex__center__center gap">
                {assignment?.type !== "Exercise" && (
                    <Popover content={gradingMenu} trigger="click">
                        <Button icon={<CustomHistoryIcon />} type="text" size="large" loading={isLoading}>
                            {t("view_history")}
                        </Button>
                    </Popover>
                )}
                <ButtonExit />
            </div>
        </div>
    );
}

export default Header;
