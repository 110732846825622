import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useRef, useState, useEffect } from "react";
import CustomPauseIcon from "../../../components/Icons/CustomPauseIcon";
import CustomPlayIcon from "../../../components/Icons/CustomPlayIcon";

function AudioPlayer({ src, type, onLoadedMetadata }) {
    const audioRef = useRef(null);
    const progressRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);

    useEffect(() => {
        const audio = audioRef.current;

        const handleLoadedMetadata = () => {
            setDuration(audio.duration);
        };

        const handleTimeUpdate = () => {
            setCurrentTime(audio.currentTime);
            if (progressRef.current) {
                progressRef.current.value = (audio.currentTime / audio.duration) * 100;
                const input = progressRef.current;
                input.style.setProperty("--range-progress", `${progressRef.current.value}%`);
            }
        };

        audio.addEventListener("loadedmetadata", handleLoadedMetadata);
        audio.addEventListener("timeupdate", handleTimeUpdate);

        return () => {
            audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
            audio.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, []);

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgressChange = (event) => {
        const audio = audioRef.current;
        const newTime = (event.target.value / 100) * audio.duration;
        audio.currentTime = newTime;
        const input = progressRef.current;
        input.style.setProperty("--range-progress", `${event.target.value}%`);
        setCurrentTime(newTime);
    };

    const handleVolumeChange = (event) => {
        const audio = audioRef.current;
        audio.volume = event.target.value;
        setVolume(event.target.value);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <div className="audio_player">
            <Button icon={isPlaying ? <CustomPauseIcon /> : <CustomPlayIcon />} className="--md btn-info" size="large" type="primary" onClick={togglePlayPause}>
                {isPlaying ? "DỪNG" : "PHÁT"}
            </Button>
            <span>{formatTime(currentTime)}</span>
            <input className="audio_progress" ref={progressRef} type="range" defaultValue="0" onChange={handleProgressChange} style={{ flex: 1 }} />
            <span>{formatTime(duration)}</span>
            {/* <input type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} style={{ width: "100px" }} /> */}
            <audio
                onLoadedMetadata={onLoadedMetadata}
                ref={audioRef}
                src={src}
                style={{ display: "none" }} // Hides the audio element
                type={type}
            ></audio>
        </div>
    );
}

export default AudioPlayer;
