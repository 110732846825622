import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Col, Row, Tree, Typography } from "antd";
import { handleFetchApi } from "../../../configs/apiRequest";
import Loading from "../../../components/Loading";
import PreviewAssignment from "./PreviewAssignment";
import NoData from "../../../components/NoData";

function SelectExercise({ selectedBook, selectedItems, setSelectedItems }) {
    const { t } = useTranslation();

    const { data: items, isLoading } = useQuery(
        [`${selectedBook?.book_id}_exams`],
        async () => {
            const exams = await handleFetchApi("get", `items/exams?book_id=${selectedBook?.book_id}&page=0&size=12`);

            if (!exams?.data?.content) {
                const exercises = await handleFetchApi("get", `items/look-up?type=Exercise&book_id=${selectedBook?.book_id}&size=20&page=0`);
                return exercises?.data?.content?.map((exercise) => ({
                    title: exercise?.name,
                    key: exercise?.id,
                    id: exercise?.id,
                    level: 2,
                    image: exercise?.content?.styles?.background,
                }));
            } else {
                console.log(exams);
                return exams?.data?.content?.map((exam) => ({
                    title: exam?.exam?.name,
                    key: exam?.exam?.id,
                    id: exam?.exam?.id,
                    level: 1,
                    children: exam?.exercises?.map((exercise) => ({
                        key: exercise?.exercise?.id,
                        id: exercise?.exercise?.id,
                        title: `${exercise?.exercise?.name}: ${exercise?.children?.length} ${t("questions")}.`,
                        image: exercise?.exercise?.content?.styles?.background,
                        level: 2,
                    })),
                }));
            }
        },
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );
    const handleCheck = (checkedIds, { checkedNodes }) => {
        setSelectedItems(checkedNodes?.filter((item) => item.level === 2));
    };

    return (
        <Row gutter={24} justify="center" align="stretch" className="selected_assignment">
            <Col span={11}>
                <Tree.DirectoryTree
                    checkable
                    autoExpandParent={true}
                    defaultExpandAll={true}
                    onCheck={handleCheck}
                    showLine={false}
                    showIcon={false}
                    treeData={items}
                    multiple={true}
                />
            </Col>
            <Col span={12} offset={1} className="preview_assignment">
                {selectedItems?.length ? (
                    <>
                        <Typography.Title className="text--center" level={5}>
                            {t("exerciseSelected", { count: selectedItems.length })}
                        </Typography.Title>
                        <PreviewAssignment items={selectedItems} />
                    </>
                ) : (
                    <NoData text={t("no_exercises_selected")} />
                )}
            </Col>
            {isLoading && <Loading />}
        </Row>
    );
}

export default SelectExercise;
