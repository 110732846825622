import { Button, Divider, Layout, Typography } from "antd";
import React, { useContext, useState } from "react";
import { StoreContext } from "../../stores";
import { MenuByRole } from "../../configs/menuByRole";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import TeacherSidebar from "./TeacherSidebar";
import { isStudent, isTeacher } from "../../configs/enums";
import { oegConfigs } from "../../configs/oeg.configs";
const { Sider } = Layout;

function SideBar() {
    const { me, setSelectedClass, setBreadcrumb } = useContext(StoreContext);
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 1024);
    const navigate = useNavigate();
    const location = useLocation();
    const menus = MenuByRole[me?.role];
    const { t } = useTranslation();

    const handleMenuClick = (menu) => {
        setSelectedClass();
        navigate(menu.link);
        setBreadcrumb({
            label: menu?.key,
        });
    };

    return (
        <Sider onCollapse={setCollapsed} defaultCollapsed={collapsed} collapsible collapsedWidth={96} width="clamp(250px, 18dvw, 300px)" className="app_sidebar">
            <img className="app_sidebar__logo" src={collapsed ? oegConfigs?.logoWithoutText : oegConfigs?.logoWithText} alt="logo" />
            {isTeacher(me) && <TeacherSidebar />}
            {isStudent(me) && (
                <>
                    <br />
                    <Typography.Title level={5} className="app_sidebar__label">
                        {t('greeting', { name: me?.name })}
                    </Typography.Title>
                </>
            )}
            <Divider />
            <Typography.Title className="app_sidebar__label" level={5}>
                {t('other')}
            </Typography.Title>
            {menus?.map((menu) => (
                <Button
                    className={`flex__start__center ${location?.pathname === menu?.link ? "active" : ""}`}
                    key={menu.key}
                    type="text"
                    size="large"
                    onClick={() => handleMenuClick(menu)}
                >
                    {menu.icon} {t(menu.key)}
                </Button>
            ))}
        </Sider>
    );
}

export default SideBar;
