import { useMutation } from "@tanstack/react-query";
import { Button, Input, message, Modal, Table } from "antd";
import React, { useContext, useState } from "react";
import * as XLSX from "xlsx";
import { AssignmentTypeEnums } from "../../../configs/enums";
import { handleFetchApi } from "../../../configs/apiRequest";
import { groupBy } from "../../../utils";
import { StoreContext } from "../../../stores";

const UploadFileScoreIelts = (props) => {
    const { setLoading, loading } = useContext(StoreContext);

    const { class_id, course_id, teacher_id } = props;
    const [assignmentName, setAssignmentName] = useState();
    const [fileData, setFileData] = useState({});

    const createTestOffline = useMutation({
        mutationFn: () => {
            message.loading("Đang tải dữ liệu lên hệ thống...");
            setLoading(true);
            let data = {
                name: assignmentName ?? "Bài Assignment chưa có tên",
                teacher_id: teacher_id,
                class_id: class_id,
                course_id: course_id,
                type: AssignmentTypeEnums.OFFLINE,
            };
            return handleFetchApi("POST", "assignment", data);
        },
        onSuccess: async (response, variables) => {
            const assignmentId = response?.data?.id;
            const gradingsByAssignment = await handleFetchApi("GET", `score/class/assignment/${assignmentId}`);
            const students = gradingsByAssignment?.data;
            const groupedData = groupBy(fileData?.data, "username");
            const studentsWithScore = await students.map((student) => {
                const studentDataUploaded = groupedData[student?.student?.username] || [];
                const studentScoreUploaded = studentDataUploaded[0];
                if (!studentScoreUploaded) return null; // Return null instead of undefined
                return {
                    ...student,
                    evaluation: { mark: studentScoreUploaded?.mark },
                    scoreDetail: {
                        score: studentScoreUploaded?.mark,
                        listening_score: studentScoreUploaded?.listening_score,
                        speaking_score: studentScoreUploaded?.speaking_score,
                        reading_score: studentScoreUploaded?.reading_score,
                        writing_score: studentScoreUploaded?.writing_score,
                    },
                    scoreReportTemplateId: 1,
                };
            }).filter(student => student !== null); // Filter out null values

            const updateScoreAll = await handleFetchApi("POST", "score/test/offline", {
                assignmentGradings: studentsWithScore,
                scoreReportTemplateId: 1,
            });
            if (updateScoreAll?.status === "S0000") {
                message.destroy();
                message.success("Cập nhật dữ liệu thành công");
                props.refetch();
                props.setCreateIeltsTest(false);
                setFileData({});
                setAssignmentName(" ");
            }
            setLoading(false);
            return;
        },
    });

    function readExcel(file, fileName, setFileData) {
        const reader = new FileReader();

        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0]; // Assuming we're reading the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Assuming first row contains headers
            const headers = jsonData.shift();
            const result = [];
            console.log("row:", jsonData?.slice(0, 99));
            jsonData?.slice(0, 99)?.forEach((row) => {
                if (row.length === 0) {
                    console.warn(`Empty row`);
                    return; // Skip processing this row
                }
                if (row.length) {
                    const obj = {
                        name: row[1],
                        username: row[3],
                        listening_score: row[4],
                        speaking_score: row[5],
                        reading_score: row[6],
                        writing_score: row[7],
                        mark: row[8],
                    };
                    result.push(obj);
                }
            });
            setFileData((prev) => ({ ...prev, fileName: fileName, data: result }));
        };

        reader.readAsArrayBuffer(file);
    }

    function handleFileUpload(event, setFileData) {
        const file = event.target.files[0];
        if (!file) return; // No file selected
        if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            alert("Please upload only .xlsx files.");
            return;
        }
        if (file.size > 25 * 1024 * 1024) {
            // 50MB in bytes
            alert("File size exceeds the maximum limit (25MB).");
            return;
        }
        // Handle file upload here
        readExcel(file, file.name, setFileData);
    }

    return (
        <div className="flex__center__center gap flex__column">
            <Input size="large" value={assignmentName} onChange={(e) => setAssignmentName(e.target.value)} placeholder="Tên bài test" />
            <input type="file" className="file-upload" accept=".xlsx" onChange={(e) => handleFileUpload(e, setFileData)} />
            {fileData?.data && (
                <Table
                    style={{ width: "100%" }}
                    pagination={false}
                    dataSource={fileData.data}
                    columns={[
                        {
                            key: "username",
                            dataIndex: "username",
                            title: "Username",
                        },
                        {
                            key: "name",
                            dataIndex: "name",
                            title: "Tên học sinh",
                        },
                        {
                            key: "mark",
                            dataIndex: "mark",
                            title: "Band score",
                        },
                    ]}
                />
            )}
            <Button loading={loading} disabled={!fileData} onClick={createTestOffline.mutate} type="primary" size="large" style={{ width: "100%" }}>
                Tạo
            </Button>
        </div>
    );
};

export default UploadFileScoreIelts;
