import React, { useState, useCallback } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import BookItem from './BookItem';
import NoData from '../../../components/NoData';

const BookList = ({ items, setSelectedBook: updateBook }) => {
    const { t } = useTranslation();
    const [selectedBook, setSelectedBook] = useState(null);

    const handleSelectBook = useCallback((book) => {
        setSelectedBook(book);
        updateBook(book);
    }, [updateBook]);

    if (!items || items.length === 0) {
        return <NoData text={t('noSearchResults')} />;
    }

    return (
        <Row gutter={16}>
            {items.map((book, index) => (
                <BookItem 
                    key={`${book.book_id}-${index}`}
                    book={book}
                    selectedBook={selectedBook}
                    onSelect={handleSelectBook}
                />
            ))}
        </Row>
    );
};

export default BookList;
