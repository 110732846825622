import React, { useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { handleFetchApi } from "../../../configs/apiRequest";
import { StoreContext } from "../../../stores";
import CustomClassIcon from "../../../components/Icons/CustomClassIcon";

function SelectClass({ selectClass, setSelectClass }) {
    const { t } = useTranslation();
    const { me } = useContext(StoreContext);

    const { data: teacherCourses, isLoading } = useQuery(
        [me?.id],
        async () => {
            const res = await handleFetchApi("get", `course/teacher/${me?.id}`);
            return res?.data;
        },
        {
            staleTime: 18000,
            cacheTime: 18000,
            refetchOnWindowFocus: false,
        }
    );

    const handleClassSelect = useCallback(
        (classId, courseId) => {
            setSelectClass({ class_id: classId, course_id: courseId });
        },
        [setSelectClass]
    );

    return (
        <div className="select_class">
            <Typography.Title level={5} className="flex__between__center">
                {t("assign_to_class")}
                <Button icon={<PlusOutlined />} className="btn-white">
                    {t("add_class")}
                </Button>
            </Typography.Title>
            <div className="flex__start__start gap">
                {teacherCourses?.map((tc) =>
                    tc?.classes?.map((cls) => (
                        <div
                            onClick={() => handleClassSelect(cls?.id, tc?.course?.id)}
                            key={cls?.id}
                            className={`card --hoverable flex__start__center gap ${selectClass?.class_id === cls?.id ? "active" : ""}`}
                        >
                            <CustomClassIcon />
                            <span>
                                Lớp {cls?.grade}
                                {cls?.name?.replace("Lớp", "")}
                                <br />
                                {cls?.school?.name}
                            </span>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default SelectClass;
