import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { getWeekRange } from "../../../utils/datetime";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import moment from "moment";
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartOnlineTime = ({studentId}) => {
    const { t } = useTranslation();
    const thisWeek = getWeekRange();
    
    const {
        data: sp,
        isLoading,
        isError,
    } = useQuery(
        [`online_${studentId}`],
        async () => {
            const online = await handleFetchApi("get", `tracking-user/user/search?user_id=${studentId}&type=ONLINE&fromDate=${thisWeek.from}&toDate=${thisWeek.to}`);
            const doing = await handleFetchApi("get", `tracking-user/user/search?user_id=${studentId}&type=DOING_TEST_ASSIGNMENT&fromDate=${thisWeek.from}&toDate=${thisWeek.to}`);
            return {
                online: online?.data,
                doing: doing?.data,
            };
        },
        {
            staleTime: 18000,
            cacheTime: 18000,
            refetchOnWindowFocus: false,
        }
    );

    const labels = sp?.online?.map((s) => moment(s.execution_date).format("DD/MM"));

    const data = {
        labels,
        datasets: [
            {
                label: t("online_time_minutes"),
                data: sp?.online?.map((s) => s.minutes),
                backgroundColor: "#FFD513",
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                label: t("assignment_time_minutes"),
                data: sp?.doing?.map((s) => s.minutes),
                backgroundColor: "#82B4FF",
                borderWidth: 0,
                borderRadius: 4,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                // display: false,
            },
        },
    };

    return (
        <div className="">
            <Bar data={data} options={options} />
        </div>
    );
};

export default ChartOnlineTime;
