import { Breadcrumb, Col, Row, Table, Typography, Button, Modal, Image } from "antd";
import React, { Suspense, lazy, useContext, useState } from "react";
import { DownloadOutlined, ExportOutlined, HomeOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { fixedNumber } from "../../utils/validation";
import { StoreContext } from "../../stores";
import ErrorPage from "../Error";
import { t } from "i18next";

const HeaderDashboard = lazy(() => import("../../components/HeaderDashboard"));
const URL = "https://sachviet.s3.amazonaws.com/books/resources/0ae36c43-9d37-40e9-915b-2f02b0a4930c/27abb95d-175e-4c14-889c-fc71b36c12b1.png";

function checkAssignmentIsTestOffline({ assignment = {} }) {
    if (!assignment.type) return false;
    if (assignment.type !== "TEST_OFFLINE") return false;
    return true;
}

function ScoreReport(props) {
    const { me, setMe } = useContext(StoreContext);
    const [visible, setVisible] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);
    const [modalState, setModalState] = useState({
        open: false,
        pdfUrl: null,
    });

    let navigate = useNavigate();
    const {
        data: scoreReport,
        isLoading,
        isError,
    } = useQuery(
        ["ScoreReport"],
        async () => {
            const studentScore = await handleFetchApi("GET", "score/student/assignment");
            const data = studentScore?.data?.map((r) => ({ ...r.assignment, ...r.assignment_grading }));
            return data.sort((a, b) => b.created_at - a.created_at);
        },
        {
            staleTime: 120000, // 2 minutes
            cacheTime: 120000,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const viewAssignmentDetail = (assignment) => {
        const { score_report_url } = assignment;
        if (!checkAssignmentIsTestOffline({ assignment })) return navigate(`/assignment/${assignment?.assignment?.id}`);
        if (score_report_url === "IN_PROGRESS") {
            return Modal.info({
                title: "Chờ một chút !",
                content: "File điểm đang được tạo trên hệ thống, vui lòng chờ vài phút rồi thực hiện lại thao tác!",
                centered: true,
            });
        }
        if (score_report_url) window.open(score_report_url, "_blank");
    };

    const columns = [
        {
            key: "assignmentName",
            title: "Tên bài TEST",
            dataIndex: "name",
            render: (record) => <b>{record}</b>,
        },
        {
            key: "evaluation",
            title: "Điểm tổng",
            dataIndex: "evaluation",
            render: (record) => (
                <Typography.Title level={5} type="danger" strong style={{ marginBottom: 0 }}>
                    {fixedNumber(record?.mark)}
                </Typography.Title>
            ),
            sorter: (a, b) => a.evaluation?.mark - b.evaluation?.mark,
        },
        {
            key: "actions",
            title: "Xem chi tiết",
            dataIndex: "actions",
            render: (value, record) => {
                return (
                    <div>
                        <Button onClick={() => viewAssignmentDetail(record)} type="link" size="small">
                            Xem chi tiết <ExportOutlined />
                        </Button>
                        {record?.type === "TEST_OFFLINE" && (
                            <Button href={record?.score_report_url} target="blank" type="link" size="small" danger>
                                Tải xuống <DownloadOutlined />
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    if (isLoading) return <Loading />;
    if (isError) return <ErrorPage />;

    return (
        <Suspense fallback={<Loading />}>
            <HeaderDashboard />
            <section id="p_manager" className="page flex__start__center flex__column">
                <Row className="page__header container" justify="center" align="middle">
                    <Col span={24}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">
                                    <HomeOutlined />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{t("score_report")}</Breadcrumb.Item>
                        </Breadcrumb>
                        {/* <Button className="btn_extra" type="link">
                        Quản lý Khóa học
                    </Button> */}
                    </Col>
                </Row>
                <section className="container manage_students">
                    <div className="flex__between__center">
                        <div style={{ paddingBottom: 12 }}>
                            {t("score_report_of")}
                            <Typography.Title type="danger" level={4}>
                                {me?.name}
                            </Typography.Title>
                            <Typography.Text>
                                Đối với bài thi IELTS, bạn có thể xem chú thích về cấp độ{" "}
                                <b
                                    onClick={() => {
                                        setImgUrl(URL);
                                        setVisible(true);
                                    }}
                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                >
                                    TẠI ĐÂY
                                </b>
                            </Typography.Text>
                        </div>
                        {/* <Button icon={<DownloadOutlined />} type="primary" danger>
                            Tải xuống
                        </Button> */}
                    </div>
                    <Table pagination={false} dataSource={scoreReport} columns={columns} rowKey="id" />
                </section>
            </section>
            {/* <Image
                style={{ display: "none" }}
                preview={{
                    visible,
                    src: imgUrl,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                }}
            /> */}
        </Suspense>
    );
}

export default ScoreReport;
