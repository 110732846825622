import { useMutation } from "@tanstack/react-query";
import { Button, Input, Modal, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../stores";
import { handleFetchApi } from "../../../configs/apiRequest";
import { t } from "i18next";

function ForMeTab(props) {
    const { setLoading } = useContext(StoreContext);
    const [code, setCode] = useState();

    useEffect(() => {
        const codeHelper = (code) => {
            if (code.length === 4) setCode((code += "-"));
            if (code.length === 9) setCode((code += "-"));
        };
        if (code) codeHelper(code);

        return () => {
            // setCode()
        };
    }, [code]);

    const activateCode = useMutation({
        mutationFn: async (values) => {
            setLoading(true);
            return handleFetchApi('post', 'admin/book-codes/activate', { code: code })
        },
        onSuccess: async (response, variables) => {
            setLoading(false);
            if (response?.status === "S0000") {
                Modal.success({
                    title: t("success"),
                    content: t("activate_book_success"),
                });
            } else
                Modal.error({
                    title: t("error"),
                    content: t("activate_book_fail"),
                });
            return;
        },
    });

    return (
        <div className="activate-forme flex__center__center flex__column">
            <Typography.Title level={4}>{t("activate_label")}</Typography.Title>
            <Input maxLength={14} size="large" onChange={(e) => setCode(e.target.value)} value={code} placeholder="abcd-1234-abcd" />
            <Button disabled={!code} type="primary" size="large" onClick={() => activateCode.mutate(code)}>
                {t("confirm")}
            </Button>
        </div>
    );
}

export default ForMeTab;
