import { Divider, message, Modal, Typography } from "antd";
import { handleFetchApi } from "../configs/apiRequest";
import { updateBookmark } from "./book";
import CustomWarningIcon from "../components/Icons/CustomWarningIcon";
import { AASSIGNMENT } from "../apis/exercise";

export const getNumberQuestions = (selectedExercise) => {
    let type = selectedExercise.content?.exercise_type;
    switch (type) {
        case "choice":
            return selectedExercise.content?.v_ids?.length;

        case "fill":
            return selectedExercise.content?.children?.length;

        case "matching":
            return selectedExercise.content?.children?.length / 2;

        default:
            return 1;
    }
};

export const startAssignmentNoGrade = ({ assignment, navigate }) => {
    handleFetchApi("POST", `assignment/${assignment.id}/start`);
    const book_id = assignment?.bookContent?.book_id;
    const index = assignment?.bookContent?.first_page;
    updateBookmark({
        book_id: book_id,
        index: index,
    });
    navigate(`/book/${assignment?.bookContent?.book_id}`);
};

export const confirmStartAssignment = ({ assignment, navigate }) =>
    Modal.warning({
        title: assignment?.name,
        icon: <CustomWarningIcon />,
        className: "--centered",
        closable: true,
        okText: "BẮT ĐẦU LÀM BÀI",
        width: "clamp(350px, 95%, 500px)",
        onOk: async () => {
            message.loading("Đang chuẩn bị dữ liệu...");
            const { data: grading } = await handleFetchApi("POST", AASSIGNMENT.start(assignment?.id));
            if (grading) {
                message.destroy();
                navigate(`/assignment/${assignment?.id}/grading/${grading?.id}`);
            }
        },
        content: (
            <>
                <Typography.Text type="warning" className="--mb-1">
                    {assignment?.itemIds?.length} Câu hỏi - {assignment?.timeDown} phút
                </Typography.Text>
                <div className="card --padding flex__start__start flex__column">
                    <Typography.Title level={5}>Hướng dẫn làm bài</Typography.Title>
                    <Typography.Text>Bạn có thể thay đổi câu trả lời trong quá trình làm bài</Typography.Text>
                    <Divider style={{ margin: "12px 0" }} />
                    <Typography.Title level={5}>Thông tin bài test</Typography.Title>
                    <Typography.Text className="--dotted">Có tất cả {assignment?.itemIds?.length} câu hỏi trong bài test này</Typography.Text>
                    <Typography.Text className="--dotted">Mỗi câu hỏi có thể có một hoặc nhiều đáp án đúng</Typography.Text>
                    <Typography.Text className="--dotted">Bạn sẽ đọc và trả lời câu hỏi trong từng đoạn văn một</Typography.Text>
                    <Typography.Text className="--dotted">Bạn chỉ được phép làm bài trong thời gian quy định</Typography.Text>
                </div>
            </>
        ),
    });
